
import { AnswerType } from '@codeserk/happy-food-server'
import { Component, computed, defineComponent, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

import LayoutSingle from '@/components/SurveyPlay/LayoutSingle.vue'
import UnlockModalContent from '@/components/SurveyPlay/UnlockModalContent.vue'
import { config } from '../../modules/config/config.setup'
import { activeSurvey, activeSurveyId, addAnswer } from '../../modules/survey/survey.store'
import { isLocked, lock, unlock } from '@/modules/common/common.store'

export default defineComponent({
  components: {
    UnlockModalContent,
  },

  setup() {
    const state = {
      showUnlockModal: ref<boolean>(false),
    }

    const getters = {
      id: activeSurveyId,
      survey: activeSurvey,
      title: computed((): string => {
        return `${getters.survey.value?.title ?? ''}`
      }),

      isLocked,
      layoutComponent: computed((): Component => LayoutSingle),
    }

    const methods = {
      async onAnswer(type: AnswerType) {
        if (!getters.id.value) {
          return
        }

        await addAnswer(getters.id.value, 0, type)
      },

      async toggleLock() {
        if (getters.isLocked.value) {
          if (!config.value.securityCode) {
            return methods.unlock()
          }

          state.showUnlockModal.value = true
        } else {
          lock()
        }
      },

      async unlock() {
        state.showUnlockModal.value = false
        unlock()
      },
    }

    onBeforeRouteLeave(() => {
      if (getters.isLocked.value) {
        return false
      }
    })

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
