import { AnswerType, SavedSurvey, StatsAggregation } from '@codeserk/happy-food-server'
export interface LocalSavedSurvey extends SavedSurvey {
  /** Id of the survey when it was created locally. */
  localId?: string

  /** Id of the survey in the server. Only present if the survey is synced. */
  serverId?: string

  /** Last time when the survey was synced. */
  syncedAt?: string
}

/** Question index, ISO date, Value */
export type SurveyLocalStats = [number, number, number]

export type StatsAggregationsByDay = Record<string, StatsAggregation>

export interface StatsToSync {
  readonly surveyId: string

  readonly questionIndex: number

  readonly date: string

  readonly answerType: AnswerType
}

export const SURVEY_VALUE_TO_TYPE: Record<number, AnswerType> = {
  '-2': AnswerType.VeryBad,
  '-1': AnswerType.Bad,
  '1': AnswerType.Good,
  '2': AnswerType.VeryGood,
}
