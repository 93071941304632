
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { activeSurveyId, setActiveSurveyId, surveys } from '../modules/survey/survey.store'
import { getScoredAnswerType } from '@/modules/survey/survey.model'
import { isLocked } from '@/modules/common/common.store'
import { isSyncing } from '@/modules/survey/survey.store'

import FaceImage from '@/components/FaceImage.vue'

export default defineComponent({
  components: {
    FaceImage,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()

    const getters = {
      surveys,
      isLocked,
      isSyncing,

      isSettingsPage: computed((): boolean => route.path === '/settings'),
      isCreateSurveyPage: computed((): boolean => route.path === '/create-survey'),
    }

    const methods = {
      getScoredAnswerType,

      isSelected: (id: string, localId?: string): boolean =>
        ['survey:view', 'survey:edit', 'survey:play'].includes(route?.name as string) &&
        (id === activeSurveyId.value || localId === activeSurveyId.value),

      gotoSurvey(id: string) {
        setActiveSurveyId(id)
        router.push({ name: 'survey:view' })
      },
    }

    return {
      ...getters,
      ...methods,
    }
  },
})
