export const AUTH_REPOSITORY_TOKEN = 'AUTH_REPOSITORY_TOKEN'
export const AUTH_API_TOKEN = 'AUTH_API_TOKEN'

export const SURVEY_API_TOKEN = 'SURVEY_API_TOKEN'
export const SURVEY_REPOSITORY_TOKEN = 'SURVEY_REPOSITORY_TOKEN'

export const STATS_API_TOKEN = 'STATS_API_TOKEN'
export const STATS_REPOSITORY_TOKEN = 'STATS_REPOSITORY_TOKEN'

export const PUBLIC_SURVEY_API_TOKEN = 'PUBLIC_SURVEY_API_TOKEN'
export const PUBLIC_SURVEY_REPOSITORY_TOKEN = 'PUBLIC_SURVEY_REPOSITORY_TOKEN'
