
import { defineComponent, onMounted, ref } from 'vue'
import { loadConfig } from './modules/config/config.setup'
import { initCloud } from './modules/cloud/cloud.setup'
import { loadSurveys, setActiveSurveyId, surveys } from './modules/survey/survey.store'

import SideNav from '@/components/SideNav.vue'

export default defineComponent({
  components: {
    SideNav,
  },

  setup() {
    const state = {
      isReady: ref<boolean>(false),
    }

    onMounted(async () => {
      await loadConfig()
      await initCloud()
      await loadSurveys()

      if (surveys.value.length > 0) {
        setActiveSurveyId(surveys.value[0].id)
      }

      state.isReady.value = true
    })

    return {
      ...state,
    }
  },
})
