/**
 * Application configuration.
 */
export const config: {
  apiBaseUrl: string
  publicBaseUrl: string
  frontendBaseUrl: string
} = {
  apiBaseUrl: process.env.VUE_APP_API_BASE_URL,
  publicBaseUrl: process.env.VUE_APP_PUBLIC_BASE_URL,
  frontendBaseUrl: process.env.VUE_APP_FRONTEND_BASE_URL,
}
