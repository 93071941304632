<template>
  <div class="tab-questions" v-if="answerTypes">
    <div class="header">
      <ion-icon class="icon" name="help-outline" size="large" />
      <h1>Question</h1>
    </div>

    <ion-list lines="full" class="ion-no-margin" v-for="(question, index) in survey.questions" :key="index">
      <ion-item>
        <ion-label position="fixed" :color="dirty.text && !isValidQuestionText(question) ? 'danger' : ''"
          >Text</ion-label
        >
        <ion-input
          placeholder="Give this question some text..."
          :value="question.text"
          @input="emitUpdateQuestion(index, { ...question, text: $event.target.value })"
          @keyup.enter="$emit('next')"
          @keyup="dirty.text = true"
        />
        <ion-note
          slot="end"
          :color="question.text.length > validations.question.text.maxLength ? 'danger' : ''"
          v-text="`${question.text.length} / ${validations.question.text.maxLength}`"
        />
      </ion-item>

      <ion-list-header lines="full">
        <ion-label :color="!hasEnoughAnswers(question) ? 'danger' : ''">Answers (minimum 2)</ion-label>
      </ion-list-header>
      <ion-item v-for="answerType in answerTypes" :key="answerType">
        <ion-note
          slot="end"
          :color="question.answers[answerType].text.length > validations.question.answer.maxLength ? 'danger' : ''"
          v-text="`${question.answers[answerType].text.length} / ${validations.question.answer.maxLength}`"
        />
        <ion-avatar slot="end">
          <img class="face-img" :src="`/assets/img/face-${answerType}.png`" />
        </ion-avatar>
        <ion-input
          placeholder="Insert here some text..."
          :value="question.answers[answerType].text"
          @input="
            emitUpdateAnswer(index, answerType, {
              ...question.answers[answerType],
              text: $event.target.value,
            })
          "
        />
        <ion-checkbox
          color="primary"
          :checked="question.answers[answerType].enabled"
          @ion-change="
            emitUpdateAnswer(index, answerType, {
              ...question.answers[answerType],
              enabled: $event.target.checked,
            })
          "
          slot="start"
        ></ion-checkbox>
      </ion-item>
    </ion-list>
  </div>
</template>

<script lang="ts">
import { AnswerDetails, AnswerType, Question } from '@codeserk/happy-food-server'
import {
  isValidQuestion,
  hasEnoughAnswers,
  isValidQuestionText,
  SURVEY_VALIDATION_RULES,
} from '@/modules/survey/survey.model'
import { reactive, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  props: {
    survey: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:question'],

  setup(props: any, { emit }: any) {
    const route = useRoute()

    const constants = {
      answerTypes: [AnswerType.VeryBad, AnswerType.Bad, AnswerType.Good, AnswerType.VeryGood],
      validations: SURVEY_VALIDATION_RULES,
    }
    const state = {
      dirty: reactive({
        text: false,
      }),
    }

    const methods = {
      isValidQuestion,
      isValidQuestionText,
      hasEnoughAnswers,

      emitUpdateQuestion(index: number, question: Question) {
        emit('update:question', { index, question })
      },

      emitUpdateAnswer(index: number, type: AnswerType, answer: AnswerDetails) {
        const question = {
          ...props.survey.questions[index],
          answers: {
            ...props.survey.questions[index].answers,
            [type]: answer,
          },
        }

        emit('update:question', { index, question })
      },

      reset() {
        state.dirty.text = false
      },
    }

    watch(
      () => route.path,
      () => methods.reset(),
    )

    return {
      ...state,
      ...constants,
      ...methods,
    }
  },
}
</script>

<style lang="scss" scoped>
.header {
  text-align: center;

  .icon {
    width: 150px;
    height: 150px;
    margin: auto;
    text-align: center;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 1em;
  }
}
</style>
