
import { defineComponent, ref } from 'vue'
import { config } from '@/modules/config/config.setup'
import { wait } from '../../util/time'

export default defineComponent({
  props: {
    isLock: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['unlocked', 'cancel'],

  setup(props: any, { emit }: any) {
    const state = {
      code: ref<string>(''),
      hasError: ref<boolean>(false),
    }

    const methods = {
      async addCode(number: number) {
        if (state.code.value.length < 4) {
          state.code.value += number.toString()

          if (props.isLock && state.code.value.length === 4) {
            if (state.code.value === config.value.securityCode) {
              emit('unlocked')
            } else {
              state.hasError.value = true

              await wait(1000)

              state.code.value = ''
              state.hasError.value = false
            }
          }
        }
      },
    }

    return {
      ...state,
      ...methods,
    }
  },
})
