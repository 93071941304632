<template>
  <div class="share-section">
    <ion-list>
      <ion-list-header v-text="'Visualization'" />

      <ion-item lines="none">
        <ion-label v-text="'Link'" slot="start" />

        <ion-input type="url" :value="link" readonly />
      </ion-item>
      <ion-item lines="none">
        <ion-note
          >Use this link to share the results of your survey. Users will be able to see the <em>questions</em> and the
          <em>statistics</em>.</ion-note
        >
      </ion-item>
      <ion-item>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-button size="small" fill="clear" expand="block" color="secondary" :href="link" target="_blank">
                <ion-label v-text="'Visit'" />
                <ion-icon slot="end" name="globe-outline" size="small" />
              </ion-button>
            </ion-col>
            <ion-col>
              <ion-button size="small" fill="clear" expand="block" color="tertiary">
                <ion-label v-text="'Share'" />
                <ion-icon slot="end" name="share-outline" size="small" />
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>

    <ion-list>
      <ion-list-header v-text="'API'" />

      <ion-item button :href="linkDocs" target="_blank">
        <ion-label v-text="'Check documentation'" />
        <ion-icon slot="start" name="information-sharp" size="small" />
      </ion-item>

      <ion-item button :href="linkSurvey" target="_blank">
        <ion-label v-text="'Survey'" />
        <ion-avatar slot="start">
          <face-image answer-type="veryGood" />
        </ion-avatar>
      </ion-item>

      <ion-item button :href="linkStats" target="_blank">
        <ion-label v-text="'Statistics'" />
        <ion-icon slot="start" name="stats-sharp" size="small" />
      </ion-item>

      <ion-item button :href="linkStatsAggregated" target="_blank">
        <ion-icon slot="start" name="calendar-outline" size="small" />
        <ion-label v-text="'Statistics aggregated by...'" />
        <ion-select
          ref="select"
          slot="end"
          placeholder="Select One"
          :value="aggregationType"
          interface="popover"
          @ion-change="aggregationType = $event.target.value"
          @click.prevent="onClicked"
        >
          <ion-select-option
            v-for="(type, index) in aggregationsTypes"
            :key="index"
            v-text="type[0]"
            :value="type[1]"
          />
        </ion-select>
      </ion-item>

      <br />

      <ion-item lines="none">
        <ion-label v-text="'Token'" slot="start" />

        <ion-input :value="token" readonly />

        <ion-button slot="end" fill="clear" size="small">
          <ion-label v-text="'Copy'" />
          <ion-icon slot="end" name="clipboard-outline" size="small" />
        </ion-button>
      </ion-item>
      <ion-item lines="none">
        <ion-note
          >This is the token needed to use the <em>API</em>. This token grants <strong>read</strong> permissions to the
          bearer. Check the documentation to learn how to use it.</ion-note
        >
      </ion-item>

      <div class="ion-padding ion-text-center">
        <ion-button color="danger">
          <ion-label v-text="'Reset token'" />
          <ion-icon slot="end" name="refresh-outline" size="small" />
        </ion-button>
      </div>
    </ion-list>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { answerTypes } from '@/modules/survey/survey.store'
import { StatsAggregationType, Survey } from '@codeserk/happy-food-server'

import FaceImage from '@/components/FaceImage.vue'
import { config } from '../../../config'

export default defineComponent({
  components: {
    FaceImage,
  },

  props: {
    survey: {
      type: Object,
      default: () => (({} as any) as Survey),
    },
  },

  setup(props: any) {
    const constants = {
      answerTypes,
      aggregationsTypes: Object.entries(StatsAggregationType),
    }

    const state = {
      aggregationType: ref<StatsAggregationType>(StatsAggregationType.Day),
    }

    const getters = {
      token: computed(() => props.survey?.readToken),
      encodedToken: computed((): string => (getters.token.value ? encodeURIComponent(getters.token.value) : '')),
      link: computed((): string | undefined => {
        if (!getters.encodedToken.value) {
          return
        }

        return `${config.frontendBaseUrl}/share/${getters.encodedToken.value}`
      }),

      linkDocs: computed(() => `${config.publicBaseUrl}/docs`),
      linkSurvey: computed((): string => `${config.publicBaseUrl}/public/v1/survey?k=${getters.encodedToken.value}`),
      linkStats: computed(
        (): string => `${config.publicBaseUrl}/public/v1/survey/stats?k=${getters.encodedToken.value}`,
      ),
      linkStatsAggregated: computed(
        (): string =>
          `${config.publicBaseUrl}/public/v1/survey/question/0/stats/aggregated?aggregation=${state.aggregationType.value}&k=${getters.encodedToken.value}`,
      ),
    }

    return {
      ...constants,
      ...state,
      ...getters,
      onClicked() {
        //
      },
    }
  },
})
</script>

<style lang="scss" scoped>
ion-avatar {
  width: 18px;
  height: 18px;

  .md & {
    margin-inline-end: 32px;
  }
}
</style>
