
import { computed, defineComponent, reactive, watch } from 'vue'
import { isValidDescription, isValidTitle, SURVEY_VALIDATION_RULES } from '@/modules/survey/survey.model'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    survey: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['update:title', 'update:description'],

  setup(props: any) {
    const route = useRoute()

    const constants = {
      validations: SURVEY_VALIDATION_RULES,
    }
    const state = {
      dirty: reactive({
        title: false,
        description: false,
      }),
    }

    const getters = {
      // Colors
      titleColor: computed((): string => (!isValidTitle(props.survey) && state.dirty.title ? 'danger' : '')),
      descriptionColor: computed((): string =>
        !isValidDescription(props.survey) && state.dirty.description ? 'danger' : '',
      ),
    }

    // Methods
    const methods = {
      reset() {
        state.dirty.title = false
        state.dirty.description = false
      },
    }

    watch(
      () => route.path,
      () => methods.reset(),
    )

    return {
      ...constants,
      ...state,
      ...getters,
      ...methods,
    }
  },
})
