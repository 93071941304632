import { computed, ref } from 'vue'
import { Plugins } from '@capacitor/core'
import { AndroidFullScreen } from '@ionic-native/android-full-screen'

const { StatusBar } = Plugins

// State
const state = {
  isLocked: ref<boolean>(false),
}

// Getters
export const isLocked = computed(() => state.isLocked.value)

// Mutations
function changeLock(isLocked: boolean) {
  state.isLocked.value = isLocked
}

// Actions
export function lock() {
  changeLock(true)

  StatusBar.hide()
  AndroidFullScreen.isImmersiveModeSupported()
    .then(() => AndroidFullScreen.immersiveMode())
    .catch(error => console.debug('Immersive mode is not supported'))
}

export function unlock() {
  changeLock(false)

  StatusBar.show()
  AndroidFullScreen.isImmersiveModeSupported()
    .then(() => AndroidFullScreen.showSystemUI())
    .catch(error => console.debug('Immersive mode is not supported'))
}
