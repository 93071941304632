<template>
  <div class="settings-general-segment">
    <ion-list>
      <ion-list-header>
        Cloud
      </ion-list-header>

      <ion-item lines="none">
        <ion-checkbox
          color="primary"
          :checked="isBackupEnabled"
          slot="start"
          @ion-change="onBackupChanged($event.target.checked)"
        ></ion-checkbox>
        <ion-label>Backup enabled</ion-label>

        <ion-spinner slot="end" v-if="isLoading" />
        <ion-icon v-else slot="end" :name="isActive ? 'cloud-outline' : 'cloud-offline-outline'" />
      </ion-item>
      <ion-item>
        <ion-note>Whether your surveys should be kept in-sync in the cloud.</ion-note>
      </ion-item>
    </ion-list>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue'
import { config } from '@/modules/config/config.setup'
import { alertController } from '@ionic/vue'
import { isLoading, isEnabled, isActive, enable, disable } from '../../modules/cloud/cloud.setup'

export default defineComponent({
  setup() {
    const state = {
      config,

      isBackupEnabled: ref<boolean>(config.value.backupEnabled),
      isChangingBackup: ref<boolean>(false),
    }

    const getters = {
      isLoading,
      isActive,
      isEnabled,
    }

    const methods = {
      async onBackupChanged(isEnabled: boolean) {
        if (state.isChangingBackup.value) {
          return
        }

        state.isChangingBackup.value = true

        if (isEnabled) {
          await enable()

          state.isBackupEnabled.value = true
          state.isChangingBackup.value = false
        } else {
          state.isBackupEnabled.value = false
          const alert = await alertController.create({
            header: 'Disable cloud backup',
            message: 'Are you sure you want to disable the cloud backup?',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  state.isBackupEnabled.value = true

                  nextTick(() => (state.isChangingBackup.value = false))
                },
              },
              {
                text: 'Yes',
                handler: async () => {
                  await disable()

                  state.isChangingBackup.value = false
                },
              },
            ],
          })
          return alert.present()
        }
      },
    }

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-list-header {
  margin-top: 1em;
  margin-bottom: 10px;
}
ion-item-divider {
  padding-top: 10px;
  padding-bottom: 10px;
}

.item-delay {
  .slider-label {
    padding: 0 1em;
  }
}

ion-note {
  margin-bottom: 1em;
}
</style>
