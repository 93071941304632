import { AnswerType, PublicApi, SavedSurvey } from '@codeserk/happy-food-server'
import { Inject } from 'typedi'
import { PUBLIC_SURVEY_API_TOKEN } from '../../../constants'
import { PublicSurveyRepository } from '../interfaces/public-survey.repository'
import { StatsAggregationsByDay } from '../interfaces/survey.interface'

export class PublicSurveyServerRepository implements PublicSurveyRepository {
  /**
   * Constructor.
   *
   * @param client
   */
  constructor(@Inject(PUBLIC_SURVEY_API_TOKEN) protected readonly client: PublicApi) {}

  async getSurvey(token: string): Promise<SavedSurvey> {
    const response = await this.client.getSurveyFromToken({ k: token })

    return response.data as any
  }

  async getSurveyStats(token: string, questionIndex: number): Promise<StatsAggregationsByDay> {
    const response = await this.client.getStatsAggregatedFromToken({
      k: token,
      questionIndex,
      aggregation: 'day',
      lastDays: 7,
    })

    return response.data.reduce((result, group) => {
      result[group.aggregation] = {
        [AnswerType.VeryBad]: group[AnswerType.VeryBad],
        [AnswerType.Bad]: group[AnswerType.Bad],
        [AnswerType.Good]: group[AnswerType.Good],
        [AnswerType.VeryGood]: group[AnswerType.VeryGood],
      }

      return result
    }, {} as StatsAggregationsByDay)
  }
}
