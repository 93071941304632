import {
  AnswerDetails,
  AnswerDetailsWithType,
  AnswerType,
  Question,
  QuestionStatsByDay,
  StatsAggregation,
  Survey,
} from '@codeserk/happy-food-server'
import { LocalSavedSurvey, StatsAggregationsByDay } from './interfaces/survey.interface'
import { answerTypePercentageValue, answerTypes } from './survey.store'
import moment from 'moment'

/**
 * Whether the survey is synced or not.
 *
 * @param survey
 */
export function isSynced(survey: LocalSavedSurvey): boolean {
  return !!survey.serverId
}

/**
 * Gets the active answers from a survey
 *
 * @TODO Make it available for other questions (not just the first).
 * @param survey
 */
export function getActiveAnswers(survey: Survey): AnswerDetailsWithType[] {
  return answerTypes.map(type => ({ ...survey.questions[0].answers[type], type })).filter(answer => answer.enabled)
}

/**
 * Gets the total number of answers from a survey.
 *
 * @TODO Make it available for other questions (not just the first).
 * @param survey
 */
export function getTotalAnswers(survey: Survey): number {
  return Object.values(survey.questions[0].answers).reduce((result, answer) => {
    result += answer.quantity

    return result
  }, 0)
}

/**
 * Gets the total number of answers from an aggregation.
 *
 * @param aggregation
 */
export function getTotalAnswersFromAggregation(aggregation: StatsAggregation): number {
  return Object.values(aggregation).reduce((total, value) => total + value, 0)
}

/**
 * Gets the score from a survey. Taken from the questions.
 *
 * @TODO Make it available for other questions (not just the first).
 */
export function getScore(survey: Survey): number {
  const stats = getAggregationFromAnswers(survey.questions[0].answers)

  return getScoreFromAggregation(stats)
}

/**
 * Gets the stats aggregations from the answers.
 *
 * @param answers
 * @returns stats aggregation.
 */
export function getAggregationFromAnswers(answers: Record<AnswerType, AnswerDetails>): StatsAggregation {
  return Object.entries(answers).reduce(
    (result, [type, details]) => {
      result[type as AnswerType] = details.quantity

      return result
    },
    {
      [AnswerType.VeryBad]: 0,
      [AnswerType.Bad]: 0,
      [AnswerType.Good]: 0,
      [AnswerType.VeryGood]: 0,
    } as StatsAggregation,
  )
}

/**
 * Gets score from a given aggregation.
 *
 * @param aggregation
 */
export function getScoreFromAggregation(aggregation: StatsAggregation): number {
  const totalAnswers = getTotalAnswersFromAggregation(aggregation)
  if (totalAnswers === 0) {
    return 0
  }

  const multiplier = 1 / totalAnswers

  return Object.entries(aggregation).reduce((score, entry) => {
    const percentageValue = answerTypePercentageValue[entry[0] as AnswerType]

    return score + multiplier * percentageValue * entry[1]
  }, 0)
}

/**
 * Gets the representative answer type for a given survey.
 * Determines the general mood for a survey.
 *
 * @param survey
 */
export function getScoredAnswerType(survey: Survey): AnswerType | undefined {
  const stats = getAggregationFromAnswers(survey.questions[0].answers)

  return getScoredAnswerTypeFromAggregation(stats)
}

/**
 * Gets the representative answer type for a given aggregation.
 *
 * @param aggregation
 */
export function getScoredAnswerTypeFromAggregation(aggregation: StatsAggregation): AnswerType | undefined {
  const totalAnswers = getTotalAnswersFromAggregation(aggregation)
  if (!totalAnswers) {
    return
  }

  const score = getScoreFromAggregation(aggregation)
  if (score > 0.75) {
    return AnswerType.VeryGood
  }
  if (score > 0.5) {
    return AnswerType.Good
  }
  if (score > 0.25) {
    return AnswerType.Bad
  }

  return AnswerType.VeryBad
}

/**
 * Gets the stats for a number of days.
 *
 * @param statsByDay
 * @param numDays
 */
export function getStatsForLastDays(
  statsByDay: StatsAggregationsByDay | undefined,
  numDays: number,
): QuestionStatsByDay {
  if (!statsByDay) {
    return []
  }

  const stats = Object.entries(statsByDay).map(([day, stats]) => ({
    day: moment(`${day} 15:00`, 'YYYY-MM-DD HH:mm'),
    stats,
  }))

  const now = moment()
  return stats
    .filter(dayStats => now.diff(dayStats.day, 'days') >= 0 && now.diff(dayStats.day, 'days') < numDays)
    .sort((dayStatsA, dayStatsB) => dayStatsB.day.unix() - dayStatsA.day.unix())
    .map(dayStats => ({ ...dayStats, day: dayStats.day.toDate(), dayFormatted: dayStats.day.format('YYYY-MM-DD') }))
}

// Validations
export const SURVEY_VALIDATION_RULES = {
  title: {
    required: true,
    maxLength: 80,
  },
  description: {
    maxLength: 500,
  },
  question: {
    text: {
      required: true,
      maxLength: 80,
    },
    minAnswers: 2,
    answer: {
      maxLength: 20,
    },
  },
}

export function getTitleError(survey: Survey): string | undefined {
  if (!survey.title) {
    return 'Title is required'
  }
  if (survey.title.length > SURVEY_VALIDATION_RULES.title.maxLength) {
    return 'Title is too long'
  }
}

export function isValidTitle(survey: Survey): boolean {
  return !getTitleError(survey)
}

export function getDescriptionError(survey: Survey): string | undefined {
  if ((survey.description?.length ?? 0) > SURVEY_VALIDATION_RULES.description.maxLength) {
    return 'Description is too long'
  }
}

export function isValidDescription(survey: Survey): boolean {
  return !getDescriptionError(survey)
}

export function getQuestionTextError(question: Question): string | undefined {
  if (!question.text) {
    return 'Question text is required'
  }
  if (question.text.length > SURVEY_VALIDATION_RULES.question.text.maxLength) {
    return 'Question text is too long'
  }
}

export function isValidQuestionText(question: Question): boolean {
  return !getQuestionTextError(question)
}

export function hasEnoughAnswers(question: Question): boolean {
  const enabledAnswers = Object.values(question.answers).filter(answer => answer.enabled)

  return enabledAnswers.length >= SURVEY_VALIDATION_RULES.question.minAnswers
}

export function getQuestionError(question: Question): string | undefined {
  const textError = getQuestionTextError(question)
  if (textError) {
    return textError
  }

  const answers = Object.values(question.answers)
  if (answers.some(answer => answer.text && answer.text.length > SURVEY_VALIDATION_RULES.question.answer.maxLength)) {
    return 'Question has an answer with a text that is too long'
  }

  const enabledAnswers = answers.filter(answer => answer.enabled)
  if (enabledAnswers.length < SURVEY_VALIDATION_RULES.question.minAnswers) {
    return 'There are too little answers enabled'
  }
}

export function isValidQuestion(question: Question): boolean {
  return !getQuestionError(question)
}

export function hasValidQuestions(survey: Survey): boolean {
  return !survey.questions.some(question => !isValidQuestion(question))
}

export function isValidSurvey(survey: Survey): boolean {
  return isValidTitle(survey) && isValidDescription(survey) && hasValidQuestions(survey)
}
