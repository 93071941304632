
import { computed, defineComponent, nextTick, ref } from 'vue'
import { useRouter } from 'vue-router'
import { wait } from '@/util/time'
import { showDeleteSurveyAlert } from '@/alerts/delete-survey.alert'
import { activeSurveyId, deleteSurvey, activeSurvey, getSurveyStatsByDay } from '../modules/survey/survey.store'
import { StatsAggregationsByDay } from '../modules/survey/interfaces/survey.interface'

import OverviewSection from './SurveyView/Sections/OverviewSection.vue'
import StatisticsSection from './SurveyView/Sections/StatisticsSection.vue'
import ShareSection from './SurveyView/Sections/ShareSection.vue'

enum Section {
  General = 'overview',
  Statistics = 'statistics',
  Share = 'share',
}

export default defineComponent({
  components: {
    OverviewSection,
    StatisticsSection,
    ShareSection,
  },

  setup() {
    const router = useRouter()

    const state = {
      section: ref<Section>(Section.General),

      isPopoverOpen: ref<boolean>(false),
      canOpenPopover: ref<boolean>(true),
      popoverEvent: ref(),
    }

    const getters = {
      activeSurveyId,
      activeSurvey,
      title: computed((): string => getters.activeSurvey.value?.title ?? ''),

      statsByDay: computed(
        (): StatsAggregationsByDay => {
          if (!getters.activeSurveyId.value) {
            return {}
          }

          return getSurveyStatsByDay(getters.activeSurveyId.value, 0) ?? {}
        },
      ),
    }

    const methods = {
      async showDeleteSurveyAlert() {
        const shouldDelete = await showDeleteSurveyAlert()

        if (shouldDelete && getters.activeSurveyId.value) {
          await deleteSurvey(getters.activeSurveyId.value)

          nextTick(() => router.push({ name: 'root' }))
        }

        methods.closePopover()
      },

      async togglePopover(event: any) {
        if (state.isPopoverOpen.value) {
          return methods.closePopover()
        }
        if (!state.canOpenPopover.value) {
          return
        }

        state.popoverEvent.value = event
        state.isPopoverOpen.value = true
      },

      async closePopover() {
        state.isPopoverOpen.value = false
        state.canOpenPopover.value = false

        await wait(250)

        state.canOpenPopover.value = true
      },

      goTo(link: string) {
        methods.closePopover()

        nextTick(() => router.push(link))
      },
    }

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
