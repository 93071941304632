import { SurveyApi, SavedSurvey, Survey } from '@codeserk/happy-food-server'
import Container, { Service } from 'typedi'
import { SURVEY_API_TOKEN } from '../../../constants'
import { SurveyRepository } from '../interfaces/survey.repository'

@Service()
export class SurveyServerRepository implements SurveyRepository {
  private _client: SurveyApi | undefined

  async fetch(): Promise<SavedSurvey[]> {
    if (!this.client) {
      throw new Error('Not authenticated.')
    }

    const response = await this.client.get()
    return (response.data as any) as SavedSurvey[]
  }
  async create(survey: Survey): Promise<SavedSurvey> {
    if (!this.client) {
      throw new Error('Not authenticated.')
    }

    const response = await this.client.createOne({ createSurveyRequest: survey as any })
    return (response.data as any) as SavedSurvey
  }
  async update(survey: SavedSurvey): Promise<SavedSurvey> {
    if (!this.client) {
      throw new Error('Not authenticated')
    }

    const response = await this.client.updateOne({ id: survey.id, updateSurveyRequest: survey as any })
    return (response.data as any) as SavedSurvey
  }

  async delete(survey: SavedSurvey): Promise<void> {
    if (!this.client) {
      throw new Error('Not authenticated')
    }

    await this.client.deleteOne({ id: survey.id })
  }

  async deleteAll(): Promise<void> {
    if (!this.client) {
      throw new Error('Not authenticated')
    }

    await this.client.deleteFromUser()
  }

  get isReady(): boolean {
    return !!this.client
  }

  protected get client(): SurveyApi | undefined {
    if (this._client) {
      return this._client
    }
    if (Container.has(SURVEY_API_TOKEN)) {
      return Container.get(SURVEY_API_TOKEN)
    }
  }
}
