<template>
  <div class="settings-general-segment">
    <ion-list>
      <ion-list-header>
        Security
      </ion-list-header>

      <ion-item lines="none">
        <ion-checkbox
          color="primary"
          :checked="isCodeEnabled"
          slot="start"
          @ion-change="onSecurityCodeEnabledChanged($event.target.checked)"
        ></ion-checkbox>
        <ion-label>Security code</ion-label>
        <ion-input :type="isCodeVisible ? 'text' : 'password'" maxlength="4" readonly :value="config.securityCode" />
        <ion-button fill="clear" slot="end" @click="isCodeVisible = !isCodeVisible">
          <ion-icon slot="icon-only" :name="isCodeVisible ? 'eye-off-outline' : 'eye-outline'" />
        </ion-button>
      </ion-item>
      <ion-item>
        <ion-note>Code used to unlock the <em>survey</em> when you are asking feedback.</ion-note>
      </ion-item>

      <ion-list-header v-text="'Survey'" />

      <ion-item-group>
        <ion-item lines="none" class="item-delay">
          <ion-label>Feedback delay</ion-label>
          <ion-range
            :min="1"
            :max="5"
            :step="1"
            snaps
            pin
            color="secondary"
            :value="config.feedbackDelay"
            @ion-change="updateFeedbackDelay($event.detail.value)"
          >
            <ion-label slot="start" class="slider-label">1</ion-label>
            <ion-label slot="end" class="slider-label">5</ion-label>
          </ion-range>
        </ion-item>
        <ion-item>
          <ion-note
            >Time to wait after a user has given feedback. This is useful to prevent a single user giving feedback
            multiple times.</ion-note
          >
        </ion-item>
      </ion-item-group>
    </ion-list>

    <ion-modal :is-open="isSettingCode" css-class="unlock-modal">
      <ion-page>
        <ion-content class="ion-padding">
          <unlock-modal-content @unlocked="unlock" @cancel="cancelSetCode" @submit="onNewCodeSubmitted" />
        </ion-content>
      </ion-page>
    </ion-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue'
import UnlockModalContent from '@/components/SurveyPlay/UnlockModalContent.vue'
import { config, updateSecurityCode, updateFeedbackDelay } from '@/modules/config/config.setup'
import { alertController } from '@ionic/vue'

export default defineComponent({
  components: {
    UnlockModalContent,
  },

  setup() {
    const state = {
      config,

      isCodeEnabled: ref<boolean>(!!config.value.securityCode),
      isCodeVisible: ref<boolean>(false),
      isChangingCode: ref<boolean>(false),
      isSettingCode: ref<boolean>(false),
      newCode: ref<string | null>(null),
    }

    const methods = {
      updateSecurityCode,
      updateFeedbackDelay,

      async onSecurityCodeEnabledChanged(isEnabled: boolean) {
        if (state.isChangingCode.value) {
          return
        }

        state.isChangingCode.value = true

        if (isEnabled) {
          state.isCodeEnabled.value = true
          state.isSettingCode.value = true
          state.newCode.value = null
        } else {
          state.isCodeEnabled.value = false
          const alert = await alertController.create({
            header: 'Disable security code',
            message: 'Are you sure you want to disable the security code?',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  state.isCodeEnabled.value = true

                  nextTick(() => (state.isChangingCode.value = false))
                },
              },
              {
                text: 'Yes',
                handler: async () => {
                  methods.updateSecurityCode()
                  state.isChangingCode.value = false
                },
              },
            ],
          })
          return alert.present()
        }
      },

      cancelSetCode() {
        state.isSettingCode.value = false
        state.isCodeEnabled.value = false

        nextTick(() => (state.isChangingCode.value = false))
      },

      onNewCodeSubmitted(newCode: string) {
        state.isChangingCode.value = false
        state.isSettingCode.value = false

        methods.updateSecurityCode(newCode)
      },
    }

    return {
      ...state,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-list-header {
  margin-top: 1em;
  margin-bottom: 10px;
}
ion-item-divider {
  padding-top: 10px;
  padding-bottom: 10px;
}

.item-delay {
  .slider-label {
    padding: 0 1em;
  }
}

ion-note {
  margin-bottom: 1em;
}
</style>
