
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { isValidDescription, isValidSurvey, isValidTitle } from '@/modules/survey/survey.model'

import GeneralTab from '@/views/SurveyView/ModifyView/GeneralTab.vue'
import QuestionsTab from '@/views/SurveyView/ModifyView/QuestionsTab.vue'
import { useRoute, useRouter } from 'vue-router'
import { Question } from '@codeserk/happy-food-server'
import { createSurvey, newSurvey, setActiveSurveyId } from '../modules/survey/survey.store'

export default defineComponent({
  components: {
    GeneralTab,
    QuestionsTab,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()

    const state = {
      survey: ref(newSurvey()),
      currentSlide: ref<number>(0),
    }

    const getters = {
      isNextDisabled: computed((): boolean => {
        if (state.currentSlide.value === 0) {
          return !isValidTitle(state.survey.value) || !isValidDescription(state.survey.value)
        }

        return !isValidSurvey(state.survey.value)
      }),
    }

    const methods = {
      onQuestionUpdated({ index, question }: { index: number; question: Question }) {
        state.survey.value.questions[index] = question
      },

      async onSlideChanged(event: any) {
        state.currentSlide.value = await event.target.getActiveIndex()
      },

      async next() {
        if (getters.isNextDisabled.value) {
          return
        }

        if (state.currentSlide.value < 1) {
          state.currentSlide.value++
        } else {
          // Create survey
          const createdSurvey = await createSurvey(state.survey.value)
          setActiveSurveyId(createdSurvey.id)

          nextTick(() => router.push({ name: 'survey:view', params: { id: createdSurvey.id } }))
        }
      },

      reset() {
        state.survey.value = newSurvey()
        state.currentSlide.value = 0
      },
    }

    onMounted(() => (state.survey.value = newSurvey()))
    watch(state.currentSlide, (slide: number) => {
      const slides = document.getElementById('slides') as any
      slides.swiper.slideTo(slide)
    })

    watch(
      () => route.path,
      () => methods.reset(),
    )

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
