import { AuthApi } from '@codeserk/happy-food-server'
import { Inject, Service } from 'typedi'
import { AUTH_API_TOKEN } from '../../../constants'
import { AuthRepository } from '../interfaces/auth.repository'

@Service()
export class AuthServerRepository implements AuthRepository {
  /**
   * Constructor.
   *
   * @param client
   */
  constructor(@Inject(AUTH_API_TOKEN) protected readonly client: AuthApi) {}

  async getToken(deviceId: string): Promise<string> {
    const response = await this.client.connect({ deviceId })

    return response?.data
  }
}
