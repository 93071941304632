
import { defineComponent } from 'vue'
import { AnswerType } from '@codeserk/happy-food-server'
export default defineComponent({
  props: {
    answerType: {
      type: String,
      default: undefined as AnswerType | undefined,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
})
