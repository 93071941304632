export enum ConfigKey {
  // General
  SecurityCode = 'security.code',
  FeedbackDelay = 'survey.feedback-delay',

  // Cloud
  BackupEnabled = 'backup.enabled',
}

export interface Config {
  // General
  securityCode?: string
  feedbackDelay: number

  // Cloud
  backupEnabled: boolean
}

export const CONFIG_DEFAULTS: Config = {
  // General
  feedbackDelay: 1,

  // Cloud
  backupEnabled: false,
}
