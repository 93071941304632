<template>
  <div class="layout-single">
    <div class="header">
      <h2 v-text="survey.questions[0].text"></h2>
    </div>

    <div class="answers">
      <div
        v-for="answerType in answerTypes"
        :key="answerType"
        class="answer"
        :class="{
          'ion-activatable': canAnswer,
          active: isActive(answerType),
          disabled: !canAnswer,
          [`answer-${answerType}`]: true,
        }"
        @click="answer(answerType)"
      >
        <div class="face-container">
          <img class="face" :src="`/assets/img/face-${answerType}.png`" />
        </div>
        <h3 v-text="survey.questions[0].answers[answerType].text" />

        <ion-ripple-effect type="unbounded" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { AnswerType } from '@codeserk/happy-food-server'
import { IonRippleEffect, toastController } from '@ionic/vue'
import { config } from '@/modules/config/config.setup'

export default defineComponent({
  props: {
    survey: {
      type: Object,
      required: true,
    },
  },

  emits: ['answer'],

  components: {
    IonRippleEffect,
  },

  setup(props: any, { emit }: any) {
    let activeTimeout: number | undefined = undefined
    const constants = {
      answerTypes: [AnswerType.VeryBad, AnswerType.Bad, AnswerType.Good, AnswerType.VeryGood],
    }

    const state = {
      currentType: ref<AnswerType | null>(null),
    }

    const getters = {
      canAnswer: computed((): boolean => !state.currentType.value),
    }

    const methods = {
      answer(type: AnswerType) {
        if (!getters.canAnswer.value) {
          return
        }

        clearTimeout(activeTimeout)
        state.currentType.value = type
        emit('answer', type)

        this.openToastOptions()

        activeTimeout = setTimeout(() => {
          state.currentType.value = null
        }, config.value.feedbackDelay * 1000)
      },

      async openToastOptions() {
        const toast = await toastController.create({
          header: 'Thanks for your feedback!',
          position: 'bottom',
          duration: config.value.feedbackDelay * 1000,
        })
        return toast.present()
      },

      isActive(type: AnswerType): boolean {
        return state.currentType.value === type
      },
    }

    return {
      ...constants,
      ...state,
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
.layout-single {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .header {
    margin: 1em;
  }

  .answers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.5em;

    .answer {
      position: relative;
      padding: 2em;
      opacity: 1;
      transition: opacity 0.4s ease-in-out;

      .face-container {
        border-radius: 50%;
        background: transparent;
        transition: background 0.4s ease-in-out;

        .face {
          max-height: 20vh;
          filter: invert(100%);
        }
      }

      h3 {
        height: 1em;
        text-align: center;
      }

      &.disabled {
        opacity: 0.4;
      }
      &.active {
        opacity: 1;

        &.answer-veryBad .face-container {
          background: #7c0606;
        }
        &.answer-bad .face-container {
          background: #ffa500;
        }
        &.answer-good .face-container {
          background: #0474a0;
        }
        &.answer-veryGood .face-container {
          background: #008000;
        }
      }
    }

    @media (max-width: 500px) {
      flex-wrap: wrap;

      .face {
        width: 20vw;
      }
    }
  }

  @media (prefers-color-scheme: dark) {
    .answer .face-container .face {
      filter: none !important;
    }
  }
}
</style>
