
import { AnswerDetails, AnswerType, Question } from '@codeserk/happy-food-server'
import {
  isValidQuestion,
  hasEnoughAnswers,
  isValidQuestionText,
  SURVEY_VALIDATION_RULES,
} from '@/modules/survey/survey.model'
import { reactive, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  props: {
    survey: {
      type: Object,
      required: true,
    },
  },

  emits: ['update:question'],

  setup(props: any, { emit }: any) {
    const route = useRoute()

    const constants = {
      answerTypes: [AnswerType.VeryBad, AnswerType.Bad, AnswerType.Good, AnswerType.VeryGood],
      validations: SURVEY_VALIDATION_RULES,
    }
    const state = {
      dirty: reactive({
        text: false,
      }),
    }

    const methods = {
      isValidQuestion,
      isValidQuestionText,
      hasEnoughAnswers,

      emitUpdateQuestion(index: number, question: Question) {
        emit('update:question', { index, question })
      },

      emitUpdateAnswer(index: number, type: AnswerType, answer: AnswerDetails) {
        const question = {
          ...props.survey.questions[index],
          answers: {
            ...props.survey.questions[index].answers,
            [type]: answer,
          },
        }

        emit('update:question', { index, question })
      },

      reset() {
        state.dirty.text = false
      },
    }

    watch(
      () => route.path,
      () => methods.reset(),
    )

    return {
      ...state,
      ...constants,
      ...methods,
    }
  },
}
