import { AuthApi, PublicApi } from '@codeserk/happy-food-server'
import Container from 'typedi'
import { config } from './config'
import {
  AUTH_API_TOKEN,
  AUTH_REPOSITORY_TOKEN,
  PUBLIC_SURVEY_API_TOKEN,
  PUBLIC_SURVEY_REPOSITORY_TOKEN,
} from './constants'
import { AuthServerRepository } from './modules/auth/repositories/auth.server-repository'
import { PublicSurveyServerRepository } from './modules/survey/repositories/public-survey.server-repository'

// Clients
Container.set(AUTH_API_TOKEN, new AuthApi(undefined, config.apiBaseUrl))
Container.set(PUBLIC_SURVEY_API_TOKEN, new PublicApi(undefined, config.apiBaseUrl))

// Repositories
Container.set(AUTH_REPOSITORY_TOKEN, Container.get(AuthServerRepository))
Container.set(PUBLIC_SURVEY_REPOSITORY_TOKEN, Container.get(PublicSurveyServerRepository))
