import { StatsApi, StatsItem } from '@codeserk/happy-food-server'
import Container, { Service } from 'typedi'
import { STATS_API_TOKEN } from '../../../constants'
import { StatsRepository } from '../interfaces/stats.repository'

@Service()
export class StatsServerRepository implements StatsRepository {
  private _client: StatsApi | undefined

  async insertStats(surveyId: string, questionId: number, stats: StatsItem[]): Promise<void> {
    await this.client?.insertStats({ id: surveyId, question: questionId, insertStatsRequest: { stats } })
  }

  async migrateSurvey(surveyAId: string, surveyBId: string): Promise<void> {
    await this.client?.migrateStats({ id: surveyAId, surveyBId })
  }

  protected get client(): StatsApi | undefined {
    if (this._client) {
      return this._client
    }
    if (Container.has(STATS_API_TOKEN)) {
      return Container.get(STATS_API_TOKEN)
    }
  }
}
