
/* eslint-disable prettier/prettier */

import moment from 'moment'
import { defineComponent, onMounted, onUnmounted, ref, watchEffect, WatchStopHandle } from 'vue'
import { Chart, ChartData, ChartTooltipItem } from 'chart.js'
import { AnswerType, QuestionStatsByDay } from '@codeserk/happy-food-server'

export default defineComponent({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    const isDarkMode = !!window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    const state = {
      ctx: ref(null),
      chart: ref<Chart | null>(null),
    }

    const methods = {
      getDayForAnswer(day: Date, answer: AnswerType): string {
        switch (answer) {
        case AnswerType.VeryBad:
          return moment(day).format('YYYY-MM-DD')
        case AnswerType.Bad:
          return moment(day).format('YYYY-MM-DD')
        case AnswerType.Good:
          return moment(day).format('YYYY-MM-DD')
        case AnswerType.VeryGood:
          return moment(day).format('YYYY-MM-DD')
        }
      },

      render(data: QuestionStatsByDay) {
        if (state.chart.value) {
          state.chart.value.data.datasets![0].data = data.map(stats => ({
            x: stats.stats[AnswerType.VeryGood],
            y: methods.getDayForAnswer(stats.day, AnswerType.VeryGood),
          }))
          state.chart.value.data.datasets![1].data = data.map(stats => ({
            x: stats.stats[AnswerType.Good],
            y: methods.getDayForAnswer(stats.day, AnswerType.Good),
          }))
          state.chart.value.data.datasets![2].data = data.map(stats => ({
            x: -stats.stats[AnswerType.Bad],
            y: methods.getDayForAnswer(stats.day, AnswerType.Bad),
          }))
          state.chart.value.data.datasets![3].data = data.map(stats => ({
            x: -stats.stats[AnswerType.VeryBad],
            y: methods.getDayForAnswer(stats.day, AnswerType.VeryBad),
          }))

          state.chart.value.update()
          return
        }

        state.chart.value = new Chart(state.ctx.value as any, {
          type: 'horizontalBar',
          data: {
            datasets: [
              {
                label: 'Very good',
                data: data.map(stats => ({
                  x: stats.stats[AnswerType.VeryGood],
                  y: methods.getDayForAnswer(stats.day, AnswerType.VeryGood),
                })),
                backgroundColor: '#00800050',
                borderColor: '#008000',
                barThickness: 10,
                borderWidth: 1,
                minBarLength: 3,
              },
              {
                label: 'Good',
                data: data.map(stats => ({
                  x: stats.stats[AnswerType.Good],
                  y: methods.getDayForAnswer(stats.day, AnswerType.Good),
                })),
                backgroundColor: '#0474a050',
                borderColor: '#0474a0',
                barThickness: 10,
                borderWidth: 1,
                minBarLength: 3
              },
              {
                label: 'Bad',
                data: data.map(stats => ({
                  x: -stats.stats[AnswerType.Bad],
                  y: methods.getDayForAnswer(stats.day, AnswerType.Bad),
                })),
                backgroundColor: '#ffa50050',
                borderColor: '#ffa500',
                barThickness: 10,
                borderWidth: 1,
                minBarLength: 3
              },
              {
                label: 'Very bad',
                data: data.map(stats => ({
                  x: -stats.stats[AnswerType.VeryBad],
                  y: methods.getDayForAnswer(stats.day, AnswerType.VeryBad),
                })),
                backgroundColor: '#80000050',
                borderColor: '#800000',
                barThickness: 10,
                borderWidth: 1,
                minBarLength: 3,

              },
            ],
          },
          options: {
            responsive: true,
            showLines: true,
            tooltips: {
              callbacks: {
                label(tooltipItem: ChartTooltipItem, data: ChartData) {
                  const dataset = data.datasets![tooltipItem.datasetIndex!]

                  return `[${dataset.label}] ${Math.abs(parseInt(tooltipItem.value ?? '0'))} answer(s)`
                }
              }
            },
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    offsetGridLines: false,
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : undefined,
                    zeroLineColor: isDarkMode ? 'white' : 'black'
                  },
                  ticks: {
                    fontColor: isDarkMode ? 'white' : undefined,
                    callback(value: number) {
                      if (Math.round(value) !== value) {
                        return ''
                      }

                      return Math.abs(value)
                    }
                  },
                },
              ],
              yAxes: [
                {
                  type: 'time',
                  offset: true,
                  time: {
                    unit: 'day',
                  },
                  gridLines: {
                    offsetGridLines: true,
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : undefined,
                    zeroLineColor:  isDarkMode ? 'white' : undefined
                  },
                  ticks: {
                    beginAtZero: true,
                    fontColor: isDarkMode ? 'white' : undefined
                  }
                },
              ],
            },
          },
        })
      },
    }

    let stopWatcher: WatchStopHandle | undefined
    onMounted(() => {
      stopWatcher = watchEffect(() => {
        methods.render(props.data as any)
      })
    })

    onUnmounted(() => {
      if (stopWatcher) {
        stopWatcher()
      }
    })

    return {
      ...state,
      ...methods,
    }
  },
})
