<template>
  <div class="face-container" :class="[`answer-${answerType ?? 'empty'}`, { active: isActive, disabled: isDisabled }]">
    <img class="face" :src="`/assets/img/face-${answerType ?? 'empty'}.png`" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { AnswerType } from '@codeserk/happy-food-server'
export default defineComponent({
  props: {
    answerType: {
      type: String,
      default: undefined as AnswerType | undefined,
    },

    isActive: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.face-container {
  border-radius: 50%;
  background: transparent;
  transition: background 0.4s ease-in-out;

  .face {
    max-height: 20vh;
    filter: invert(100%);
  }

  &.active {
    opacity: 1;

    &.answer-veryBad {
      background: #7c0606;
    }
    &.answer-bad {
      background: #ffa500;
    }
    &.answer-good {
      background: #0474a0;
    }
    &.answer-veryGood {
      background: #008000;
    }
  }

  @media (prefers-color-scheme: dark) {
    .face {
      filter: none !important;
    }
  }
}
</style>
