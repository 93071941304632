<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>

        <ion-title>
          <ion-label v-text="title" />
        </ion-title>
      </ion-toolbar>

      <ion-toolbar>
        <ion-segment v-model="section">
          <ion-segment-button value="overview">
            <ion-label v-text="'Overview'" />
          </ion-segment-button>
          <ion-segment-button value="statistics">
            <ion-label v-text="'Statistics'" />
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <template v-if="survey">
        <overview-section v-show="section === 'overview'" :survey="survey" />
        <statistics-section v-show="section === 'statistics'" :survey="survey" :stats-by-day="statsByDay" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { SavedSurvey } from '@codeserk/happy-food-server'
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { StatsAggregationsByDay } from '../modules/survey/interfaces/survey.interface'
import { getSurveyAggregatedStatsFromToken, getSurveyFromToken } from '../modules/survey/survey.store'

import OverviewSection from './SurveyView/Sections/OverviewSection.vue'
import StatisticsSection from './SurveyView/Sections/StatisticsSection.vue'

enum Section {
  General = 'overview',
  Statistics = 'statistics',
}

export default defineComponent({
  components: {
    OverviewSection,
    StatisticsSection,
  },

  setup() {
    const route = useRoute()

    const state = {
      isLoading: ref<boolean>(false),

      survey: ref<SavedSurvey | null>(null),
      statsByDay: ref<StatsAggregationsByDay>({}),

      section: ref<Section>(Section.General),
    }

    const getters = {
      title: computed((): string => state.survey.value?.title ?? ''),
    }

    const methods = {
      async loadSurvey(token: string) {
        state.isLoading.value = true

        state.survey.value = await getSurveyFromToken(token)
        state.statsByDay.value = (await getSurveyAggregatedStatsFromToken(token, 0)) ?? {}

        state.isLoading.value = false
      },
    }

    onMounted(async () => {
      if (route.params.token) {
        await methods.loadSurvey(route.params.token as string)
      }
    })

    watch(
      () => route.path as string,
      async () => {
        if (route.name === 'survey:share') {
          return methods.loadSurvey(route.params.token as string)
        }
      },
    )

    return {
      ...state,
      ...getters,
    }
  },
})
</script>
