import { SurveyRepository } from '../interfaces/survey.repository'
import { Storage } from '@capacitor/core'
import { deepCopy } from '../../../util/object'
import { Service } from 'typedi'
import { AnswerType, ANSWER_TYPE_VALUE, SavedSurvey, StatsAggregation, Survey } from '@codeserk/happy-food-server'
import { LocalSavedSurvey, StatsAggregationsByDay, SurveyLocalStats } from '../interfaces/survey.interface'
import moment from 'moment'

/**
 * Local storage keys used:
 *
 * survey-ids {string[]}  Ids of all the surveys
 * survey-<id> {Survey}   Survey by that id
 */

@Service()
export class SurveyLocalRepository implements SurveyRepository {
  async fetch(): Promise<LocalSavedSurvey[]> {
    const surveyIds = await this.get('survey-ids', [])
    const surveys = await Promise.all(
      surveyIds.map(id => this.get<LocalSavedSurvey | undefined>(`survey-${id}`, undefined)),
    )

    return surveys.filter(Boolean) as LocalSavedSurvey[]
  }

  async getSurveyStatsByDay(survey: SavedSurvey, questionIndex: number): Promise<StatsAggregationsByDay | undefined> {
    return this.get(`survey-stats-${survey.id}.${questionIndex}`, undefined)
  }

  async getStats(survey: SavedSurvey): Promise<SurveyLocalStats[]> {
    const { keys } = await Storage.keys()
    const allStatsKeys = keys.filter(key => key.includes(`survey-all-stats-stats-${survey.id}`))

    return (await Promise.all(allStatsKeys.map(key => this.get(key, [])))).flat()
  }

  async create(survey: Survey): Promise<SavedSurvey> {
    const date = new Date()
    const id = date.getTime().toString()
    const savedSurvey: LocalSavedSurvey = {
      ...deepCopy(survey),
      id: id,
      localId: id,
      createdAt: date.toISOString(),
      updatedAt: date.toISOString(),
    }

    return this.insert(savedSurvey)
  }

  async insert(survey: LocalSavedSurvey): Promise<LocalSavedSurvey> {
    const surveyIds = await this.get<string[]>('survey-ids', [])
    surveyIds.push(survey.id)

    await Storage.set({ key: `survey-ids`, value: JSON.stringify(surveyIds) })
    await Storage.set({ key: `survey-${survey.id}`, value: JSON.stringify(survey) })

    return survey
  }

  async update(survey: LocalSavedSurvey): Promise<LocalSavedSurvey> {
    await Storage.set({ key: `survey-${survey.id}`, value: JSON.stringify(survey) })

    return survey
  }

  async addStats(survey: SavedSurvey, questionIndex: number, day: Date | string, answer: AnswerType): Promise<void> {
    // Update individual values
    const date = typeof day === 'string' ? day : moment(day).format('YYYY-MM-DD')
    const value = ANSWER_TYPE_VALUE[answer]

    const allStatsDay = await this.get<SurveyLocalStats[]>(`survey-all-stats-stats-${survey.id}@${date}`, [])
    allStatsDay.push([questionIndex, moment(day).unix(), value])

    await Storage.set({
      key: `survey-all-stats-stats-${survey.id}@${date}`,
      value: JSON.stringify(allStatsDay),
    })
  }

  async updateSurveyStats(
    survey: SavedSurvey,
    questionIndex: number,
    day: Date | string,
    dayStats: StatsAggregation,
  ): Promise<void> {
    let stats = await this.getSurveyStatsByDay(survey, questionIndex)
    if (!stats) {
      stats = {}
    }

    const key = typeof day === 'string' ? day : moment(day).format('YYYY-MM-DD')
    stats[key] = dayStats

    // Update aggregations
    await Storage.set({
      key: `survey-stats-${survey.id}.${questionIndex}`,
      value: JSON.stringify(stats),
    })
  }

  async migrateStats(
    surveyA: SavedSurvey,
    surveyB: SavedSurvey,
    questionIndex: number,
  ): Promise<StatsAggregationsByDay | undefined> {
    // Aggregated stats
    const stats = await this.getSurveyStatsByDay(surveyA, questionIndex)
    for (const day in stats) {
      await this.updateSurveyStats(surveyB, questionIndex, day, stats[day])
    }

    // All stats
    const { keys } = await Storage.keys()
    const allStatsKeys = keys.filter(key => key.includes(`survey-all-stats-stats-${surveyA.id}`))
    for (const key of allStatsKeys) {
      const stats = await this.get(key, [])
      const newKey = key.replace(surveyA.id, surveyB.id)

      await Storage.set({
        key: newKey,
        value: JSON.stringify(stats),
      })
    }

    return stats
  }

  async delete(survey: LocalSavedSurvey): Promise<void> {
    const surveyIds = await this.get<string[]>('survey-ids', [])
    const newSurveyIds = surveyIds.filter(id => id !== survey.id)

    await Storage.set({ key: `survey-ids`, value: JSON.stringify(newSurveyIds) })
    await Storage.remove({ key: `survey-${survey.id}` })
  }

  protected async get<T>(key: string, defaultValue: T): Promise<T> {
    try {
      const resultsJson = await Storage.get({ key })

      return (JSON.parse(resultsJson.value) as T) || defaultValue
    } catch (error) {
      console.error(error)
    }

    return defaultValue
  }
}
