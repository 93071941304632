<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start"> <ion-menu-button /> </ion-buttons>
        <ion-title>Settings</ion-title>
      </ion-toolbar>

      <ion-toolbar>
        <ion-segment v-model="section">
          <ion-segment-button value="config">
            <ion-label v-text="'General'" />
          </ion-segment-button>
          <ion-segment-button value="cloud" layout="icon-start">
            <ion-label v-text="'Cloud'" />
            <ion-icon name="cloud-outline" />
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>

    <ion-content class="content" :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Settings</ion-title>
        </ion-toolbar>
      </ion-header>

      <general-segment v-show="section === 'config'" />
      <cloud-segment v-show="section === 'cloud'" />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

import GeneralSegment from '@/views/SettingsView/GeneralSegment.vue'
import CloudSegment from '@/views/SettingsView/CloudSegment.vue'

enum Section {
  General = 'config',
  Cloud = 'cloud',
}

export default defineComponent({
  components: {
    GeneralSegment,
    CloudSegment,
  },

  setup() {
    const state = {
      section: ref<Section>(Section.General),
    }

    return {
      ...state,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-list-header {
  margin-top: 1em;
  margin-bottom: 10px;
}
ion-item-divider {
  padding-top: 10px;
  padding-bottom: 10px;
}

.item-delay {
  .slider-label {
    padding: 0 1em;
  }
}
</style>
