<template>
  <ion-app v-if="isReady">
    <ion-split-pane content-id="main-content">
      <side-nav />

      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue'
import { loadConfig } from './modules/config/config.setup'
import { initCloud } from './modules/cloud/cloud.setup'
import { loadSurveys, setActiveSurveyId, surveys } from './modules/survey/survey.store'

import SideNav from '@/components/SideNav.vue'

export default defineComponent({
  components: {
    SideNav,
  },

  setup() {
    const state = {
      isReady: ref<boolean>(false),
    }

    onMounted(async () => {
      await loadConfig()
      await initCloud()
      await loadSurveys()

      if (surveys.value.length > 0) {
        setActiveSurveyId(surveys.value[0].id)
      }

      state.isReady.value = true
    })

    return {
      ...state,
    }
  },
})
</script>

<style lang="scss">
.unlock-modal {
  .modal-wrapper {
    --width: 300px;
    --height: 440px;

    border-radius: 12px;
    background: var(--translucent-background) !important;
    backdrop-filter: blur(5px) !important;

    ion-content {
      --background: var(--translucent-background) !important;
    }

    @media (max-height: 400px) {
      --width: 100%;
    }
  }
}

/** Tweaks for md forms */
.md {
  ion-list-header {
    align-items: flex-end;
    font-weight: bold;
    font-size: 22px;
  }
  ion-note {
    align-self: unset;
    font-size: 16px;
  }
}

ion-note {
  margin-bottom: 1em;
}
ion-list-header {
  margin-bottom: 0.5em;
}
</style>
