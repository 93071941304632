
import { defineComponent, onMounted, onUnmounted, ref, watchEffect, WatchStopHandle } from 'vue'
import { Chart } from 'chart.js'
import { StatsAggregation } from '@codeserk/happy-food-server'

export default defineComponent({
  props: {
    totals: {
      type: Object as any,
      default: () => ({}),
    },
  },

  setup(props) {
    const isDarkMode = !!window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    const state = {
      ctx: ref(null),
      chart: ref<Chart | null>(null),
    }

    const methods = {
      render(totals: StatsAggregation) {
        if (state.chart.value) {
          state.chart.value.data.datasets![0].data = Object.values(totals)
          state.chart.value.update()
          return
        }

        state.chart.value = new Chart(state.ctx.value as any, {
          type: 'bar',
          data: {
            labels: ['Very bad', 'Bad', 'Good', 'Very good'],
            datasets: [
              {
                data: Object.values(totals),
                backgroundColor: ['#80000050', '#ffa50050', '#0474a050', '#00800050'],
                borderColor: ['#800000', '#ffa500', '#0474a0', '#008000'],
                borderWidth: 1,
                barThickness: 20,
                minBarLength: 10,
              },
            ],
          },
          options: {
            responsive: true,
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : undefined,
                    zeroLineColor: isDarkMode ? 'white' : undefined,
                  },
                  ticks: {
                    fontColor: isDarkMode ? 'white' : undefined,
                    beginAtZero: true,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    fontColor: isDarkMode ? 'white' : undefined,
                    beginAtZero: true,
                  },
                  gridLines: {
                    color: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : undefined,
                    zeroLineColor: isDarkMode ? 'white' : undefined,
                  },
                },
              ],
            },
          },
        })
      },
    }

    let stopWatcher: WatchStopHandle | undefined
    onMounted(() => {
      stopWatcher = watchEffect(() => {
        methods.render(props.totals)
      })
    })

    onUnmounted(() => {
      if (stopWatcher) {
        stopWatcher()
      }
    })

    return {
      ...state,
      ...methods,
    }
  },
})
