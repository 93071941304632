<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>

        <ion-title>
          <ion-label v-text="title" />
        </ion-title>

        <ion-buttons slot="end">
          <ion-button @click="togglePopover($event)">
            <ion-icon slot="icon-only" ios="ellipsis-horizontal" md="ellipsis-vertical"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-toolbar>
        <ion-segment v-model="section">
          <ion-segment-button value="overview">
            <ion-label v-text="'Overview'" />
          </ion-segment-button>
          <ion-segment-button value="statistics">
            <ion-label v-text="'Statistics'" />
          </ion-segment-button>
          <ion-segment-button value="share">
            <ion-label v-text="'Share'" />
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-popover
        :is-open="isPopoverOpen"
        css-class="survey-options-popover"
        :event="popoverEvent"
        :translucent="true"
        @onDidDismiss="closePopover"
      >
        <ion-content>
          <ion-list lines="none">
            <ion-item button detail="false" @click="goTo(`/survey/edit`)">
              <ion-label>Edit</ion-label>
              <ion-icon size="small" slot="end" name="create-outline" />
            </ion-item>
            <ion-item button detail="false" @click="goTo(`/survey/play`)" lines="full">
              <ion-label>Start Survey</ion-label>
              <ion-icon size="small" slot="end" name="happy-outline" />
            </ion-item>
            <ion-item button detail="false" @click="showDeleteSurveyAlert">
              <ion-label color="danger">Delete</ion-label>
              <ion-icon color="danger" size="small" slot="end" name="trash-outline" />
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-popover>

      <template v-if="activeSurvey">
        <overview-section v-show="section === 'overview'" :survey="activeSurvey" />
        <statistics-section v-show="section === 'statistics'" :survey="activeSurvey" :stats-by-day="statsByDay" />
        <share-section v-show="section === 'share'" :survey="activeSurvey" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, ref } from 'vue'
import { useRouter } from 'vue-router'
import { wait } from '@/util/time'
import { showDeleteSurveyAlert } from '@/alerts/delete-survey.alert'
import { activeSurveyId, deleteSurvey, activeSurvey, getSurveyStatsByDay } from '../modules/survey/survey.store'
import { StatsAggregationsByDay } from '../modules/survey/interfaces/survey.interface'

import OverviewSection from './SurveyView/Sections/OverviewSection.vue'
import StatisticsSection from './SurveyView/Sections/StatisticsSection.vue'
import ShareSection from './SurveyView/Sections/ShareSection.vue'

enum Section {
  General = 'overview',
  Statistics = 'statistics',
  Share = 'share',
}

export default defineComponent({
  components: {
    OverviewSection,
    StatisticsSection,
    ShareSection,
  },

  setup() {
    const router = useRouter()

    const state = {
      section: ref<Section>(Section.General),

      isPopoverOpen: ref<boolean>(false),
      canOpenPopover: ref<boolean>(true),
      popoverEvent: ref(),
    }

    const getters = {
      activeSurveyId,
      activeSurvey,
      title: computed((): string => getters.activeSurvey.value?.title ?? ''),

      statsByDay: computed(
        (): StatsAggregationsByDay => {
          if (!getters.activeSurveyId.value) {
            return {}
          }

          return getSurveyStatsByDay(getters.activeSurveyId.value, 0) ?? {}
        },
      ),
    }

    const methods = {
      async showDeleteSurveyAlert() {
        const shouldDelete = await showDeleteSurveyAlert()

        if (shouldDelete && getters.activeSurveyId.value) {
          await deleteSurvey(getters.activeSurveyId.value)

          nextTick(() => router.push({ name: 'root' }))
        }

        methods.closePopover()
      },

      async togglePopover(event: any) {
        if (state.isPopoverOpen.value) {
          return methods.closePopover()
        }
        if (!state.canOpenPopover.value) {
          return
        }

        state.popoverEvent.value = event
        state.isPopoverOpen.value = true
      },

      async closePopover() {
        state.isPopoverOpen.value = false
        state.canOpenPopover.value = false

        await wait(250)

        state.canOpenPopover.value = true
      },

      goTo(link: string) {
        methods.closePopover()

        nextTick(() => router.push(link))
      },
    }

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
.charts-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .survey-chart {
    flex: 1;
    width: calc(100% - 2em);
    margin: 1em;
  }
}

ion-toggle {
  width: 50px;
  height: 25px;
}
</style>
