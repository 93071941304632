<template>
  <div class="modify-general-tab">
    <div class="header">
      <ion-icon class="icon" name="pencil-outline" size="large" />
      <h1>General information</h1>
    </div>
    <ion-list>
      <ion-item lines="none">
        <ion-label :color="titleColor" position="fixed">Title</ion-label>
        <ion-input
          placeholder="Give your survey a name..."
          :value="survey.title"
          @input="$emit('update:title', $event.target.value)"
          @keyup.enter="$emit('next')"
          @keyup="dirty.title = true"
        />
        <ion-note
          slot="end"
          :color="survey.title.length > validations.title.maxLength ? 'danger' : ''"
          v-text="`${survey.title.length} / ${validations.title.maxLength}`"
        />
      </ion-item>
      <ion-item>
        <ion-label :color="descriptionColor" position="fixed">Description</ion-label>
        <ion-textarea
          placeholder="Give your survey a description..."
          :rows="5"
          :value="survey.description"
          @input="$emit('update:description', $event.target.value)"
          @keyup="dirty.description = true"
        />
        <ion-note
          slot="end"
          :color="survey.description.length > validations.description.maxLength ? 'danger' : ''"
          v-text="`${survey.description.length} / ${validations.description.maxLength}`"
        />
      </ion-item>
    </ion-list>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, watch } from 'vue'
import { isValidDescription, isValidTitle, SURVEY_VALIDATION_RULES } from '@/modules/survey/survey.model'
import { useRoute } from 'vue-router'

export default defineComponent({
  props: {
    survey: {
      type: Object,
      default: () => ({}),
    },
  },

  emits: ['update:title', 'update:description'],

  setup(props: any) {
    const route = useRoute()

    const constants = {
      validations: SURVEY_VALIDATION_RULES,
    }
    const state = {
      dirty: reactive({
        title: false,
        description: false,
      }),
    }

    const getters = {
      // Colors
      titleColor: computed((): string => (!isValidTitle(props.survey) && state.dirty.title ? 'danger' : '')),
      descriptionColor: computed((): string =>
        !isValidDescription(props.survey) && state.dirty.description ? 'danger' : '',
      ),
    }

    // Methods
    const methods = {
      reset() {
        state.dirty.title = false
        state.dirty.description = false
      },
    }

    watch(
      () => route.path,
      () => methods.reset(),
    )

    return {
      ...constants,
      ...state,
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  text-align: center;

  .icon {
    width: 150px;
    height: 150px;
    margin: auto;
    text-align: center;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 1em;
  }
}
</style>
