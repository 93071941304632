
import { defineComponent, nextTick, ref } from 'vue'
import UnlockModalContent from '@/components/SurveyPlay/UnlockModalContent.vue'
import { config, updateSecurityCode, updateFeedbackDelay } from '@/modules/config/config.setup'
import { alertController } from '@ionic/vue'

export default defineComponent({
  components: {
    UnlockModalContent,
  },

  setup() {
    const state = {
      config,

      isCodeEnabled: ref<boolean>(!!config.value.securityCode),
      isCodeVisible: ref<boolean>(false),
      isChangingCode: ref<boolean>(false),
      isSettingCode: ref<boolean>(false),
      newCode: ref<string | null>(null),
    }

    const methods = {
      updateSecurityCode,
      updateFeedbackDelay,

      async onSecurityCodeEnabledChanged(isEnabled: boolean) {
        if (state.isChangingCode.value) {
          return
        }

        state.isChangingCode.value = true

        if (isEnabled) {
          state.isCodeEnabled.value = true
          state.isSettingCode.value = true
          state.newCode.value = null
        } else {
          state.isCodeEnabled.value = false
          const alert = await alertController.create({
            header: 'Disable security code',
            message: 'Are you sure you want to disable the security code?',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  state.isCodeEnabled.value = true

                  nextTick(() => (state.isChangingCode.value = false))
                },
              },
              {
                text: 'Yes',
                handler: async () => {
                  methods.updateSecurityCode()
                  state.isChangingCode.value = false
                },
              },
            ],
          })
          return alert.present()
        }
      },

      cancelSetCode() {
        state.isSettingCode.value = false
        state.isCodeEnabled.value = false

        nextTick(() => (state.isChangingCode.value = false))
      },

      onNewCodeSubmitted(newCode: string) {
        state.isChangingCode.value = false
        state.isSettingCode.value = false

        methods.updateSecurityCode(newCode)
      },
    }

    return {
      ...state,
      ...methods,
    }
  },
})
