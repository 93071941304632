<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button v-if="currentSlide === 0" />

          <ion-button v-if="currentSlide !== 0" @click="currentSlide--">
            <ion-label v-text="'Back'" />
            <ion-icon slot="start" name="chevron-back-outline" />
          </ion-button>
        </ion-buttons>

        <ion-title v-text="'Create new Survey'" />

        <ion-buttons slot="end">
          <ion-button :disabled="isNextDisabled" @click="next">
            <ion-label v-text="currentSlide === 0 ? 'Next' : 'Create'" />
            <ion-icon slot="end" name="chevron-forward-outline" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-toolbar v-if="false">
        <div class="dots">
          <div v-for="(dot, index) in [1, 2]" :key="dot" class="dot" :class="{ active: currentSlide === index }" />
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content class="content" :fullscreen="true">
      <ion-slides id="slides" class="ion-slides" :options="{ allowTouchMove: false }">
        <ion-slide>
          <div class="container">
            <general-tab
              :survey="survey"
              @update:title="value => (survey.title = value)"
              @update:description="value => (survey.description = value)"
              @next="next"
            />
          </div>
        </ion-slide>

        <ion-slide>
          <div class="container">
            <questions-tab :survey="survey" @update:question="onQuestionUpdated" @next="next" />
          </div>
        </ion-slide>
      </ion-slides>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'
import { isValidDescription, isValidSurvey, isValidTitle } from '@/modules/survey/survey.model'

import GeneralTab from '@/views/SurveyView/ModifyView/GeneralTab.vue'
import QuestionsTab from '@/views/SurveyView/ModifyView/QuestionsTab.vue'
import { useRoute, useRouter } from 'vue-router'
import { Question } from '@codeserk/happy-food-server'
import { createSurvey, newSurvey, setActiveSurveyId } from '../modules/survey/survey.store'

export default defineComponent({
  components: {
    GeneralTab,
    QuestionsTab,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()

    const state = {
      survey: ref(newSurvey()),
      currentSlide: ref<number>(0),
    }

    const getters = {
      isNextDisabled: computed((): boolean => {
        if (state.currentSlide.value === 0) {
          return !isValidTitle(state.survey.value) || !isValidDescription(state.survey.value)
        }

        return !isValidSurvey(state.survey.value)
      }),
    }

    const methods = {
      onQuestionUpdated({ index, question }: { index: number; question: Question }) {
        state.survey.value.questions[index] = question
      },

      async onSlideChanged(event: any) {
        state.currentSlide.value = await event.target.getActiveIndex()
      },

      async next() {
        if (getters.isNextDisabled.value) {
          return
        }

        if (state.currentSlide.value < 1) {
          state.currentSlide.value++
        } else {
          // Create survey
          const createdSurvey = await createSurvey(state.survey.value)
          setActiveSurveyId(createdSurvey.id)

          nextTick(() => router.push({ name: 'survey:view', params: { id: createdSurvey.id } }))
        }
      },

      reset() {
        state.survey.value = newSurvey()
        state.currentSlide.value = 0
      },
    }

    onMounted(() => (state.survey.value = newSurvey()))
    watch(state.currentSlide, (slide: number) => {
      const slides = document.getElementById('slides') as any
      slides.swiper.slideTo(slide)
    })

    watch(
      () => route.path,
      () => methods.reset(),
    )

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
.ion-slides {
  height: 100%;

  .swiper-slide {
    display: block;
    overflow-y: auto;
    text-align: left;
  }
}

.dots {
  position: fixed;
  top: 6px;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100px;
  height: 2.1em;
  transform: translate3d(-50%, 0, 0);

  .dot {
    width: 12px;
    height: 12px;
    border: 1px solid var(--ion-color-primary);
    border-radius: 50%;
    background: transparent;
    transition: background-color 0.4s ease-in-out;

    &.active {
      background: var(--ion-color-primary);
    }
  }
}
</style>
