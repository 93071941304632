import { Configuration, StatsApi, SurveyApi } from '@codeserk/happy-food-server'
import Container from 'typedi'
import { computed, ref } from 'vue'
import { STATS_API_TOKEN, STATS_REPOSITORY_TOKEN, SURVEY_API_TOKEN, SURVEY_REPOSITORY_TOKEN } from '../../constants'
import { SurveyServerRepository } from '@/modules/survey/repositories/survey.server-repository'
import { AuthService } from '../auth/auth.service'
import { config, updateBackupEnabled } from '../config/config.setup'
import { deleteAllServerSurveys, syncSurveys } from '../survey/survey.store'
import { StatsServerRepository } from '../survey/repositories/stats.server-repository'
import { config as appConfig } from '@/config'

const auth = Container.get(AuthService)

// State
const state = {
  isInitializing: ref<boolean>(false),
  isEnabling: ref<boolean>(false),
  isDisabling: ref<boolean>(false),

  deviceId: ref<string | null>(null),
  token: ref<string | null>(null),
}

// Mutations
const mutations = {
  setIsInitializing(isInitializing: boolean) {
    state.isInitializing.value = isInitializing
  },

  setEnabling(isEnabling: boolean) {
    state.isEnabling.value = isEnabling
  },

  setDisabling(isDisabling: boolean) {
    state.isDisabling.value = isDisabling
  },

  setDeviceId(deviceId: string) {
    state.deviceId.value = deviceId
  },

  setToken(token: string | null) {
    state.token.value = token
  },
}

function setupClients() {
  // API clients
  Container.set(
    SURVEY_API_TOKEN,
    new SurveyApi(new Configuration({ accessToken: state.token.value! }), appConfig.apiBaseUrl),
  )
  Container.set(
    STATS_API_TOKEN,
    new StatsApi(new Configuration({ accessToken: state.token.value! }), appConfig.apiBaseUrl),
  )

  // Repositories
  Container.set(SURVEY_REPOSITORY_TOKEN, Container.get(SurveyServerRepository))
  Container.set(STATS_REPOSITORY_TOKEN, Container.get(StatsServerRepository))
}

// Getters
export const isLoading = computed(
  (): boolean => state.isInitializing.value || state.isEnabling.value || state.isDisabling.value,
)

export const isEnabled = computed(() => config.value.backupEnabled)

export const isActive = computed((): boolean => isEnabled.value && !!state.token.value)

// Actions
export async function initCloud() {
  mutations.setIsInitializing(true)

  try {
    mutations.setDeviceId(await auth.getDeviceId())
    if (isEnabled.value) {
      mutations.setToken(await auth.getToken())
      setupClients()
    }
  } catch (error) {
    console.error(error)
  }

  mutations.setIsInitializing(false)
}

export async function enable(): Promise<void> {
  mutations.setEnabling(true)

  mutations.setToken(await auth.getToken())
  updateBackupEnabled(true)
  setupClients()

  await syncSurveys()

  mutations.setEnabling(false)
}

export async function disable(): Promise<void> {
  mutations.setDisabling(true)

  await deleteAllServerSurveys()

  mutations.setToken(null)
  updateBackupEnabled(false)

  mutations.setDisabling(false)
}
