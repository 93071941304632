<template>
  <ion-page>
    <ion-header :class="{ hidden: isLocked }">
      <ion-toolbar>
        <ion-buttons slot="start" v-if="!isLocked">
          <ion-back-button />
        </ion-buttons>

        <ion-title v-text="title" />

        <ion-buttons slot="end">
          <ion-button @click="toggleLock">
            <ion-icon slot="start" name="lock-closed-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <component :is="layoutComponent" :survey="survey" @answer="onAnswer" />
    </ion-content>

    <ion-modal :is-open="showUnlockModal" css-class="unlock-modal" @onDidDismiss="showUnlockModal = false">
      <ion-page>
        <ion-content class="ion-padding">
          <unlock-modal-content @unlocked="unlock" @cancel="showUnlockModal = false" is-lock />
        </ion-content>
      </ion-page>
    </ion-modal>
  </ion-page>
</template>

<script lang="ts">
import { AnswerType } from '@codeserk/happy-food-server'
import { Component, computed, defineComponent, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'

import LayoutSingle from '@/components/SurveyPlay/LayoutSingle.vue'
import UnlockModalContent from '@/components/SurveyPlay/UnlockModalContent.vue'
import { config } from '../../modules/config/config.setup'
import { activeSurvey, activeSurveyId, addAnswer } from '../../modules/survey/survey.store'
import { isLocked, lock, unlock } from '@/modules/common/common.store'

export default defineComponent({
  components: {
    UnlockModalContent,
  },

  setup() {
    const state = {
      showUnlockModal: ref<boolean>(false),
    }

    const getters = {
      id: activeSurveyId,
      survey: activeSurvey,
      title: computed((): string => {
        return `${getters.survey.value?.title ?? ''}`
      }),

      isLocked,
      layoutComponent: computed((): Component => LayoutSingle),
    }

    const methods = {
      async onAnswer(type: AnswerType) {
        if (!getters.id.value) {
          return
        }

        await addAnswer(getters.id.value, 0, type)
      },

      async toggleLock() {
        if (getters.isLocked.value) {
          if (!config.value.securityCode) {
            return methods.unlock()
          }

          state.showUnlockModal.value = true
        } else {
          lock()
        }
      },

      async unlock() {
        state.showUnlockModal.value = false
        unlock()
      },
    }

    onBeforeRouteLeave(() => {
      if (getters.isLocked.value) {
        return false
      }
    })

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
ion-header {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;

  &.hidden {
    opacity: 0;
    cursor: default;
  }
}
</style>
