import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

// Views
import CreateSurvey from '@/views/CreateSurvey.vue'
import SurveyRouter from '@/views/SurveyRouter.vue'
import SurveyRoot from '@/views/SurveyRoot.vue'
import SurveyView from '@/views/SurveyView.vue'
import ModifySurveyView from '@/views/SurveyView/ModifyView.vue'
import PlaySurveyView from '@/views/SurveyView/PlayView.vue'
import SettingsView from '@/views/SettingsView.vue'
import SharedSurveyView from '@/views/SharedSurveyView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'root',
    component: SurveyRoot,
  },
  {
    path: '/create-survey',
    name: 'survey:create',
    component: CreateSurvey,
  },
  {
    path: '/survey/',
    component: SurveyRouter,
    children: [
      {
        name: 'survey:view',
        path: 'view',
        component: SurveyView,
      },
      {
        name: 'survey:edit',
        path: 'edit',
        component: ModifySurveyView,
      },
      {
        name: 'survey:play',
        path: 'play',
        component: PlaySurveyView,
      },
    ],
  },
  {
    path: '/share/:token',
    name: 'survey:share',
    component: SharedSurveyView,
  },
  {
    path: '/settings',
    component: SettingsView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
