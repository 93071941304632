
import { computed, defineComponent, ref } from 'vue'
import { AnswerType } from '@codeserk/happy-food-server'
import { IonRippleEffect, toastController } from '@ionic/vue'
import { config } from '@/modules/config/config.setup'

export default defineComponent({
  props: {
    survey: {
      type: Object,
      required: true,
    },
  },

  emits: ['answer'],

  components: {
    IonRippleEffect,
  },

  setup(props: any, { emit }: any) {
    let activeTimeout: number | undefined = undefined
    const constants = {
      answerTypes: [AnswerType.VeryBad, AnswerType.Bad, AnswerType.Good, AnswerType.VeryGood],
    }

    const state = {
      currentType: ref<AnswerType | null>(null),
    }

    const getters = {
      canAnswer: computed((): boolean => !state.currentType.value),
    }

    const methods = {
      answer(type: AnswerType) {
        if (!getters.canAnswer.value) {
          return
        }

        clearTimeout(activeTimeout)
        state.currentType.value = type
        emit('answer', type)

        this.openToastOptions()

        activeTimeout = setTimeout(() => {
          state.currentType.value = null
        }, config.value.feedbackDelay * 1000)
      },

      async openToastOptions() {
        const toast = await toastController.create({
          header: 'Thanks for your feedback!',
          position: 'bottom',
          duration: config.value.feedbackDelay * 1000,
        })
        return toast.present()
      },

      isActive(type: AnswerType): boolean {
        return state.currentType.value === type
      },
    }

    return {
      ...constants,
      ...state,
      ...getters,
      ...methods,
    }
  },
})
