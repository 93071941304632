<template>
  <ion-menu class="main-menu" content-id="main-content" type="overlay" v-show="!isLocked">
    <ion-content class="main-menu-content">
      <ion-list id="settings-list">
        <ion-list-header class="title">Happy Food</ion-list-header>
        <ion-note class="subtitle">Ask your customers...</ion-note>

        <ion-menu-toggle auto-hide="false">
          <ion-item button router-direction="root" router-link="/settings" :color="isSettingsPage ? 'primary' : ''">
            <ion-icon slot="start" name="options-outline" />
            <ion-label v-text="'Settings'" />
          </ion-item>
        </ion-menu-toggle>

        <ion-menu-toggle auto-hide="false">
          <ion-item
            button
            router-direction="root"
            router-link="/create-survey"
            :color="isCreateSurveyPage ? 'primary' : ''"
          >
            <ion-icon slot="start" name="add-circle-outline" />
            <ion-label v-text="'New survey'" />
          </ion-item>
        </ion-menu-toggle>
      </ion-list>

      <ion-list id="survey-list">
        <template v-if="surveys.length > 0">
          <ion-list-header class="header">Surveys</ion-list-header>
          <ion-menu-toggle auto-hide="false" v-for="survey in surveys" :key="survey.id">
            <ion-item
              button
              @click="gotoSurvey(survey.id)"
              :color="isSelected(survey.id, survey.localId) ? 'primary' : ''"
            >
              <ion-spinner slot="start" v-if="isSyncing(survey.id).value" />
              <ion-avatar slot="start" v-else>
                <face-image
                  :answer-type="getScoredAnswerType(survey)"
                  :is-active="isSelected(survey.id, survey.localId)"
                />
              </ion-avatar>
              <ion-label v-text="survey.title" />
            </ion-item>
          </ion-menu-toggle>
        </template>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { activeSurveyId, setActiveSurveyId, surveys } from '../modules/survey/survey.store'
import { getScoredAnswerType } from '@/modules/survey/survey.model'
import { isLocked } from '@/modules/common/common.store'
import { isSyncing } from '@/modules/survey/survey.store'

import FaceImage from '@/components/FaceImage.vue'

export default defineComponent({
  components: {
    FaceImage,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()

    const getters = {
      surveys,
      isLocked,
      isSyncing,

      isSettingsPage: computed((): boolean => route.path === '/settings'),
      isCreateSurveyPage: computed((): boolean => route.path === '/create-survey'),
    }

    const methods = {
      getScoredAnswerType,

      isSelected: (id: string, localId?: string): boolean =>
        ['survey:view', 'survey:edit', 'survey:play'].includes(route?.name as string) &&
        (id === activeSurveyId.value || localId === activeSurveyId.value),

      gotoSurvey(id: string) {
        setActiveSurveyId(id)
        router.push({ name: 'survey:view' })
      },
    }

    return {
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss">
.main-menu {
  .main-menu-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
  }

  .main-menu-content {
    --padding-start: 0;
    --padding-end: 0;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }

  .header {
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 18px;
  }

  #survey-list,
  #settings-list {
    margin-bottom: 5px;
    padding: 20px 0 0 0;

    .title {
      min-height: 20px;
      font-weight: 600;
      font-size: 22px;
    }
    .subtitle {
      display: inline-block;
      margin-bottom: 30px;
      padding-left: 16px;
      color: var(--ion-color-medium-shade);
      font-size: 16px;
    }
  }

  .button-create {
    width: 100%;
    margin: auto;
    text-align: center;
  }

  &.ios {
    #survey-list,
    #settings-list {
      padding: 20px 0 0 0;

      ion-item {
        --padding-start: 16px;
        --padding-end: 16px;
        --min-height: 50px;
      }

      .title,
      .subtitle,
      .header {
        padding-right: 16px;
        padding-left: 16px;
      }

      .header {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
