
import { computed, defineComponent } from 'vue'
import TotalsChart from '@/components/Charts/TotalsChart.vue'
import FaceImage from '@/components/FaceImage.vue'
import { Survey } from '@codeserk/happy-food-server'
import {
  getTotalAnswers,
  getActiveAnswers,
  getScoredAnswerType,
  getScore,
  getAggregationFromAnswers,
} from '@/modules/survey/survey.model'

export default defineComponent({
  props: {
    survey: {
      type: Object,
      default: () => (({} as any) as Survey),
    },
  },

  components: {
    TotalsChart,
    FaceImage,
  },

  setup(props: any) {
    const getters = {
      answers: computed(() => getActiveAnswers(props.survey)),
      score: computed(() => getScore(props.survey)),
      scoredAnswerType: computed(() => getScoredAnswerType(props.survey)),
      totals: computed(() => getAggregationFromAnswers(props.survey.questions[0].answers)),
      totalAnswers: computed(() => getTotalAnswers(props.survey)),
    }

    const methods = {
      getActiveAnswers,
      getScore,
      getScoredAnswerType,
      getAggregationFromAnswers,
    }

    return {
      ...getters,
      ...methods,
    }
  },
})
