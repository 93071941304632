import { AnswerType, SavedSurvey, StatsAggregation, Survey, SurveyLayout } from '@codeserk/happy-food-server'
import moment from 'moment'
import Container from 'typedi'
import { computed, ref } from 'vue'
import { PUBLIC_SURVEY_REPOSITORY_TOKEN } from '../../constants'
import { deepCopy } from '../../util/object'
import { isEnabled } from '../cloud/cloud.setup'
import { PublicSurveyRepository } from './interfaces/public-survey.repository'
import {
  LocalSavedSurvey,
  StatsAggregationsByDay,
  StatsToSync,
  SURVEY_VALUE_TO_TYPE,
} from './interfaces/survey.interface'
import { StatsServerRepository } from './repositories/stats.server-repository'
import { SurveyLocalRepository } from './repositories/survey.local-repository'
import { SurveyServerRepository } from './repositories/survey.server-repository'

const localRepository = Container.get(SurveyLocalRepository)
const serverRepository = Container.get(SurveyServerRepository)
const statsRepository = Container.get(StatsServerRepository)
const publicSurveyRepository = Container.get<PublicSurveyRepository>(PUBLIC_SURVEY_REPOSITORY_TOKEN)

// Constants
export const answerTypes = [AnswerType.VeryBad, AnswerType.Bad, AnswerType.Good, AnswerType.VeryGood]
export const answerTypeValue = {
  [AnswerType.VeryBad]: -2,
  [AnswerType.Bad]: -1,
  [AnswerType.Good]: 1,
  [AnswerType.VeryGood]: 2,
}
export const answerTypePercentageValue = {
  [AnswerType.VeryBad]: 0,
  [AnswerType.Bad]: 0.33,
  [AnswerType.Good]: 0.66,
  [AnswerType.VeryGood]: 1,
}

// State
const state = {
  activeId: ref<string | null>(null),
  surveys: ref<Record<string, LocalSavedSurvey>>({}),

  // <survey.id>.<questionIndex> => Stats by day
  statsByDay: ref<Record<string, StatsAggregationsByDay>>({}),

  statsToSync: ref<StatsToSync[]>([]),

  isSyncing: ref<Record<string, boolean>>({}),
}
;(window as any).$state = state

// Getters
export const surveys = computed((): LocalSavedSurvey[] => Object.values(state.surveys.value))
export const surveyById = (id: string) =>
  computed(() => state.surveys.value[id] || surveys.value.find(survey => survey.id === id || survey.localId === id))

export const activeSurveyId = computed((): string | undefined => state.activeId.value ?? undefined)
export const activeSurvey = computed(() => {
  if (!state.activeId.value) {
    return
  }

  const id = state.activeId.value
  return state.surveys.value[id] || surveys.value.find(survey => survey.id === id || survey.localId === id)
})

export const hasSurveys = computed(() => surveys.value.length > 0)

export const isSyncing = (id: string) => computed(() => state.isSyncing.value[id])

export const getSurveyStatsByDay = (id: string, questionIndex: number) =>
  state.statsByDay.value[`${id}.${questionIndex}`]

export const getSurveyStatsForDay = (id: string, questionIndex: number, date: Date) => {
  const stats = getSurveyStatsByDay(id, questionIndex)
  if (!stats) {
    return
  }

  const day = moment(date).format('YYYY-MM-DD')

  return stats[day]
}

// Mutations
const mutations = {
  setSyncing(id: string, isSyncing: boolean) {
    state.isSyncing.value[id] = isSyncing
  },
}
function setSurvey(survey: LocalSavedSurvey) {
  state.surveys.value[survey.id] = survey
}

function setSurveyStats(surveyId: string, questionIndex: number, stats: StatsAggregationsByDay) {
  for (const day in stats) {
    setSurveyStatsForDay(surveyId, questionIndex, day, stats[day])
  }
}

function setSurveyStatsForDay(
  surveyId: string,
  questionIndex: number,
  date: Date | string,
  dayStats: StatsAggregation,
) {
  const day = typeof date === 'string' ? date : moment(date).format('YYYY-MM-DD')
  if (!state.statsByDay.value[`${surveyId}.${questionIndex}`]) {
    state.statsByDay.value[`${surveyId}.${questionIndex}`] = {}
  }

  state.statsByDay.value[`${surveyId}.${questionIndex}`][day] = dayStats
}

function addStatsToSync(surveyId: string, questionIndex: number, date: Date, answerType: AnswerType) {
  state.statsToSync.value.push({
    surveyId,
    questionIndex,
    date: date.toISOString(),
    answerType,
  })
}

function removeSurvey(survey: any) {
  delete state.surveys.value[survey.id]
}

// Actions

export function createStatsAggregation(): StatsAggregation {
  return {
    [AnswerType.VeryBad]: 0,
    [AnswerType.Bad]: 0,
    [AnswerType.Good]: 0,
    [AnswerType.VeryGood]: 0,
  }
}

export function setActiveSurveyId(id: string) {
  state.activeId.value = id
}

export function newSurvey(): Survey {
  return {
    title: '',
    description: '',
    layout: SurveyLayout.Single,
    questions: [
      {
        text: '',
        answers: {
          [AnswerType.VeryBad]: { text: '', enabled: true, quantity: 0 },
          [AnswerType.Bad]: { text: '', enabled: true, quantity: 0 },
          [AnswerType.Good]: { text: '', enabled: true, quantity: 0 },
          [AnswerType.VeryGood]: { text: '', enabled: true, quantity: 0 },
        },
      },
    ],
  }
}

// Methods
export async function loadSurveys(): Promise<void> {
  const surveys = await localRepository.fetch()

  for (const survey of surveys) {
    setSurvey(survey)

    // Load local stats
    for (let index = 0; index < survey.questions.length; index++) {
      const stats = await localRepository.getSurveyStatsByDay(survey, index)
      if (stats) {
        setSurveyStats(survey.id, index, stats)
      }
    }
  }
}

export async function syncSurveys(): Promise<void> {
  for (const survey of surveys.value) {
    await syncSurvey(survey)
  }
}

export async function syncSurvey(survey: LocalSavedSurvey): Promise<void> {
  if (!isEnabled.value) {
    return
  }

  try {
    mutations.setSyncing(survey.id, true)

    // await wait(1000)

    const syncedAt = new Date().toISOString()
    // Update survey
    if (survey.serverId) {
      const updatedSurvey = await serverRepository.update(survey)

      const updatedLocalSurvey = { ...survey, ...updatedSurvey, syncedAt }
      await localRepository.update(updatedLocalSurvey)

      setSurvey(updatedLocalSurvey)
    } else {
      const createdSurvey = await serverRepository.create(survey)

      const createdLocalSurvey = {
        ...createdSurvey,
        localId: survey.localId,
        serverId: createdSurvey.id,
        syncedAt,
      }
      await localRepository.delete(survey)
      await localRepository.insert(createdLocalSurvey)

      removeSurvey(survey)
      setSurvey(createdLocalSurvey)

      for (let index = 0; index < survey.questions.length; index++) {
        const stats = await localRepository.migrateStats(survey, createdLocalSurvey, index)
        if (stats) {
          setSurveyStats(createdLocalSurvey.id, index, stats)
        }
      }

      const allStats = await localRepository.getStats(survey)
      for (const statsToSync of allStats) {
        state.statsToSync.value.push({
          surveyId: createdLocalSurvey.id,
          questionIndex: statsToSync[0],
          date: moment.unix(statsToSync[1]).toISOString(),
          answerType: SURVEY_VALUE_TO_TYPE[statsToSync[2]],
        })
      }
    }
  } catch (error) {
    console.error(error)
  }

  mutations.setSyncing(survey.id, false)
}

export async function createSurvey(survey: any): Promise<SavedSurvey> {
  const localSurvey = await localRepository.create(survey)
  setSurvey(localSurvey)

  syncSurvey(localSurvey)

  return localSurvey
}

export async function updateSurvey(survey: SavedSurvey): Promise<SavedSurvey> {
  const updatedSurvey = await localRepository.update(survey)
  setSurvey(updatedSurvey)

  if (isEnabled.value) {
    syncSurvey(updatedSurvey)
  }

  return updatedSurvey
}

export async function addAnswer(surveyId: string, questionIndex: number, type: AnswerType) {
  const survey = surveyById(surveyId).value
  if (!survey) {
    return
  }

  // Update stats
  const date = moment(new Date())
    // .subtract(Math.random() * 7, 'days')
    .toDate()

  const updatedSurvey = deepCopy(survey)
  updatedSurvey.questions[questionIndex].answers[type].quantity++

  let statsForDay = getSurveyStatsForDay(surveyId, questionIndex, date)
  if (!statsForDay) {
    statsForDay = createStatsAggregation()
  }
  statsForDay![type]++

  addStatsToSync(surveyId, questionIndex, date, type)

  await localRepository.update(updatedSurvey)
  setSurvey(updatedSurvey)

  await localRepository.updateSurveyStats(survey, questionIndex, date, statsForDay)
  await localRepository.addStats(survey, questionIndex, date, type)
  setSurveyStatsForDay(surveyId, questionIndex, date, statsForDay)

  return updateSurvey
}

export async function deleteSurvey(id: string): Promise<void> {
  const survey = surveyById(id).value
  if (!survey) {
    return
  }
  await localRepository.delete(survey)

  if (isEnabled.value) {
    await serverRepository.delete(survey)
  }

  removeSurvey(survey)
}

export async function deleteAllServerSurveys(): Promise<void> {
  if (!isEnabled) {
    return
  }

  for (const survey of surveys.value) {
    if (survey.serverId) {
      delete survey.serverId
      delete survey.syncedAt

      const updatedSurvey = await localRepository.update(survey)
      setSurvey(updatedSurvey)
    }
  }

  await serverRepository.deleteAll()
}

export async function getSurveyFromToken(token: string): Promise<SavedSurvey | null> {
  try {
    return await publicSurveyRepository.getSurvey(token)
  } catch (error) {
    console.error(error)

    return null
  }
}

export async function getSurveyAggregatedStatsFromToken(
  token: string,
  questionIndex: number,
): Promise<StatsAggregationsByDay | null> {
  try {
    return await publicSurveyRepository.getSurveyStats(token, questionIndex)
  } catch (error) {
    console.error(error)

    return null
  }
}

// Sync stats every 60m
setInterval(async () => {
  if (!isEnabled.value) {
    return
  }

  if (state.statsToSync.value.length > 0) {
    const statsBySurveyQuestion = state.statsToSync.value.reduce((result, item) => {
      const key = `${item.surveyId}.${item.questionIndex}`
      if (!result[key]) {
        result[key] = []
      }
      result[key].push(item)

      return result
    }, {} as Record<string, StatsToSync[]>)

    for (const stats of Object.values(statsBySurveyQuestion)) {
      if (stats.length > 0) {
        const surveyId = stats[0].surveyId
        const questionId = stats[0].questionIndex
        const statsFormatted = stats.map(statsItem => ({
          date: statsItem.date,
          answerType: statsItem.answerType,
        }))

        const localSurvey = surveyById(surveyId).value
        if (localSurvey) {
          state.isSyncing.value[surveyId] = true

          await serverRepository.update(localSurvey)
          await statsRepository.insertStats(localSurvey.serverId!, questionId, statsFormatted as any[])

          state.isSyncing.value[surveyId] = false
        }
      }
    }

    state.statsToSync.value = []
  }
}, 5000)
