<template>
  <ion-page>
    <ion-router-outlet />
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { surveyById } from '../modules/survey/survey.store'

export default defineComponent({
  setup() {
    const route = useRoute()

    const getters = {
      id: computed((): string => route.params.id as string),
      survey: computed((): any => surveyById(getters.id.value).value),
    }

    return {
      ...getters,
    }
  },
})
</script>
