import { computed, reactive, watch } from 'vue'
import { Config, CONFIG_DEFAULTS } from './config.interface'
import { ConfigLocalRepository } from './repositories/local.repository'
import { debounce } from 'debounce'

const repository = new ConfigLocalRepository()

// State
const state = {
  config: reactive<Config>(CONFIG_DEFAULTS),
}

// Mutations
const mutations = {
  setConfig(newConfig: Config) {
    state.config.feedbackDelay = newConfig.feedbackDelay
    state.config.securityCode = newConfig.securityCode
    state.config.backupEnabled = newConfig.backupEnabled
  },
}

// Getters
export const config = computed((): Config => state.config)

// Actions
export async function loadConfig() {
  const newConfig = await repository.get()

  mutations.setConfig(newConfig)
}

export function updateSecurityCode(code?: string) {
  state.config.securityCode = code
}

export function updateFeedbackDelay(delay: number) {
  state.config.feedbackDelay = delay
}

export function updateBackupEnabled(isEnabled: boolean) {
  state.config.backupEnabled = isEnabled
}

// Update config every 500ms
watch(
  state.config,
  debounce(async () => repository.update(state.config), 500),
)
