<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button />
        </ion-buttons>

        <ion-title v-text="title" />

        <ion-buttons slot="end">
          <ion-button @click="save" :disabled="!canSave">
            <ion-icon slot="start" name="save-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>

      <ion-toolbar>
        <ion-segment v-model="activeTab">
          <ion-segment-button value="general">
            <ion-label>General</ion-label>
          </ion-segment-button>
          <ion-segment-button value="questions">
            <ion-label>Questions</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <template v-if="survey">
        <general-tab
          v-show="activeTab === 'general'"
          :survey="survey"
          @update:title="value => (survey.title = value)"
          @update:description="value => (survey.description = value)"
        />
        <questions-tab v-show="activeTab === 'questions'" :survey="survey" @update:question="onQuestionUpdated" />
      </template>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { Question, SavedSurvey } from '@codeserk/happy-food-server'
import { computed, defineComponent, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { deepCopy } from '@/util/object'

import GeneralTab from './ModifyView/GeneralTab.vue'
import QuestionsTab from './ModifyView/QuestionsTab.vue'
import { isValidSurvey } from '@/modules/survey/survey.model'
import { activeSurvey, activeSurveyId, surveys, updateSurvey } from '../../modules/survey/survey.store'
import { toastController } from '@ionic/vue'

enum Tab {
  General = 'general',
  Questions = 'questions',
}

export default defineComponent({
  components: {
    GeneralTab,
    QuestionsTab,
  },

  setup() {
    const route = useRoute()
    const router = useRouter()

    const state = {
      survey: ref<SavedSurvey | undefined>(),
      activeTab: ref<Tab>(Tab.General),
    }

    const getters = {
      id: activeSurveyId,

      title: computed((): string => {
        return state.survey.value?.title ?? ''
      }),

      surveys,

      canSave: computed((): boolean => {
        if (!state.survey.value) {
          return false
        }

        return isValidSurvey(state.survey.value)
      }),
    }

    const methods = {
      onQuestionUpdated({ index, question }: { index: number; question: Question }) {
        if (!state.survey.value) {
          return
        }

        state.survey.value.questions[index] = question
      },

      setCurrentSurvey(id?: string) {
        state.survey.value = deepCopy(activeSurvey.value) as SavedSurvey

        state.activeTab.value = Tab.General
      },

      async save() {
        await updateSurvey(state.survey.value as any)

        router.back()

        const toast = await toastController.create({
          header: 'Changes updated successfully.',
          position: 'bottom',
          duration: 5000,
        })
        await toast.present()
      },
    }

    methods.setCurrentSurvey(route.params.id as string)

    watch(activeSurveyId, (id?: string) => methods.setCurrentSurvey(id))

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped></style>
