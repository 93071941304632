import { alertController } from '@ionic/vue'

export async function showDeleteSurveyAlert(): Promise<boolean> {
  return new Promise(resolve => {
    alertController
      .create({
        header: 'Confirm deletion',
        message: 'Are you sure you want to delete this survey?',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => resolve(false),
          },
          {
            text: 'Yes',
            handler: () => resolve(true),
          },
        ],
      })
      .then(alert => alert.present())
  })
}
