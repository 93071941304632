
import { defineComponent, nextTick, ref } from 'vue'
import { config } from '@/modules/config/config.setup'
import { alertController } from '@ionic/vue'
import { isLoading, isEnabled, isActive, enable, disable } from '../../modules/cloud/cloud.setup'

export default defineComponent({
  setup() {
    const state = {
      config,

      isBackupEnabled: ref<boolean>(config.value.backupEnabled),
      isChangingBackup: ref<boolean>(false),
    }

    const getters = {
      isLoading,
      isActive,
      isEnabled,
    }

    const methods = {
      async onBackupChanged(isEnabled: boolean) {
        if (state.isChangingBackup.value) {
          return
        }

        state.isChangingBackup.value = true

        if (isEnabled) {
          await enable()

          state.isBackupEnabled.value = true
          state.isChangingBackup.value = false
        } else {
          state.isBackupEnabled.value = false
          const alert = await alertController.create({
            header: 'Disable cloud backup',
            message: 'Are you sure you want to disable the cloud backup?',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                handler: () => {
                  state.isBackupEnabled.value = true

                  nextTick(() => (state.isChangingBackup.value = false))
                },
              },
              {
                text: 'Yes',
                handler: async () => {
                  await disable()

                  state.isChangingBackup.value = false
                },
              },
            ],
          })
          return alert.present()
        }
      },
    }

    return {
      ...state,
      ...getters,
      ...methods,
    }
  },
})
