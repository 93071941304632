
import { computed, defineComponent } from 'vue'
import { answerTypes } from '@/modules/survey/survey.store'
import {
  getTotalAnswers,
  getActiveAnswers,
  getScoredAnswerType,
  getScoredAnswerTypeFromAggregation,
  getScore,
  getStatsForLastDays,
} from '@/modules/survey/survey.model'

import ByDayChart from '@/components/Charts/ByDayChart.vue'
import FaceImage from '@/components/FaceImage.vue'
import { Survey } from '@codeserk/happy-food-server'

export default defineComponent({
  props: {
    survey: {
      type: Object,
      default: () => (({} as any) as Survey),
    },

    statsByDay: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ByDayChart,
    FaceImage,
  },

  setup(props: any) {
    const constants = {
      answerTypes,
    }

    const getters = {
      answers: computed(() => getActiveAnswers(props.survey)),
      score: computed(() => getScore(props.survey)),
      scoredAnswerType: computed(() => getScoredAnswerType(props.survey)),
      totalAnswers: computed(() => getTotalAnswers(props.survey)),

      statsByDayForLastDays: computed(() => {
        return getStatsForLastDays(props.statsByDay, 7)
      }),
    }

    const methods = {
      getActiveAnswers,
      getScore,
      getScoredAnswerType,
      getScoredAnswerTypeFromAggregation,
    }

    return {
      ...constants,
      ...getters,
      ...methods,
    }
  },
})
