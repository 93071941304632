<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button />
        </ion-buttons>
        <ion-title>Surveys</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="container content ion-padding">
        <face-image class="image" answer-type="veryGood" is-active />

        <h1>Hello!</h1>
        <ion-text color="medium" v-if="hasSurveys">
          <p>Select one of your <em>surveys</em> from the side panel.</p>
        </ion-text>
        <ion-button fill="clear" router-link="/create-survey">
          <ion-icon slot="start" name="add-circle-outline" />
          <ion-label v-text="'Create survey'" />
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { hasSurveys } from '../modules/survey/survey.store'

import FaceImage from '@/components/FaceImage.vue'

export default defineComponent({
  components: {
    FaceImage,
  },

  setup() {
    const getters = {
      hasSurveys,
    }

    return {
      ...getters,
    }
  },
})
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .image {
    max-width: 50vw;
  }

  h1 {
    margin-bottom: 2em;
  }
}
</style>
