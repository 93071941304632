<template>
  <div class="overview">
    <h1>Last <strong>7</strong> days</h1>
    <div class="results-container">
      <table class="results">
        <thead>
          <th />
          <th v-for="(answer, index) in answers" :key="index">
            <ion-avatar slot="start">
              <face-image :answer-type="answer.type" :is-active="scoredAnswerType === answer.type" />
            </ion-avatar>
          </th>
        </thead>
        <tbody>
          <tr v-for="stats in statsByDayForLastDays" :key="stats.day">
            <th v-text="stats.dayFormatted" />
            <td
              v-for="(answer, index) in answers"
              :key="index"
              :class="[
                `answer-${answer.type}`,
                { active: getScoredAnswerTypeFromAggregation(stats.stats) === answer.type },
              ]"
            >
              <span class="answer-value" v-text="stats.stats[answer.type]" />
            </td>
          </tr>
        </tbody>
      </table>

      <div class="chart-container">
        <by-day-chart :data="statsByDayForLastDays || []" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { answerTypes } from '@/modules/survey/survey.store'
import {
  getTotalAnswers,
  getActiveAnswers,
  getScoredAnswerType,
  getScoredAnswerTypeFromAggregation,
  getScore,
  getStatsForLastDays,
} from '@/modules/survey/survey.model'

import ByDayChart from '@/components/Charts/ByDayChart.vue'
import FaceImage from '@/components/FaceImage.vue'
import { Survey } from '@codeserk/happy-food-server'

export default defineComponent({
  props: {
    survey: {
      type: Object,
      default: () => (({} as any) as Survey),
    },

    statsByDay: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ByDayChart,
    FaceImage,
  },

  setup(props: any) {
    const constants = {
      answerTypes,
    }

    const getters = {
      answers: computed(() => getActiveAnswers(props.survey)),
      score: computed(() => getScore(props.survey)),
      scoredAnswerType: computed(() => getScoredAnswerType(props.survey)),
      totalAnswers: computed(() => getTotalAnswers(props.survey)),

      statsByDayForLastDays: computed(() => {
        return getStatsForLastDays(props.statsByDay, 7)
      }),
    }

    const methods = {
      getActiveAnswers,
      getScore,
      getScoredAnswerType,
      getScoredAnswerTypeFromAggregation,
    }

    return {
      ...constants,
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;

  .results-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 1em;

    .results {
      flex: 1;
      margin: 1em;
      text-align: center;

      thead {
        th ion-avatar {
          margin: auto;
          margin-bottom: 1em;
        }
      }
      tbody {
        tr td {
          vertical-align: bottom;
          padding-top: 0.25em;

          .answer-value {
            display: block;
            padding-top: 0.25em;
            padding-bottom: 0.25em;
          }

          &.active {
            .answer-value {
              border-radius: 12px;
              color: white;
              font-weight: bold;
            }

            &.answer-veryBad .answer-value {
              background: #7c0606;
            }
            &.answer-bad .answer-value {
              background: #ffa500;
            }
            &.answer-good .answer-value {
              background: #0474a0;
            }
            &.answer-veryGood .answer-value {
              background: #008000;
            }
          }
        }
      }
    }

    .chart-container {
      width: calc(50vw - 400px);
      min-width: 400px;
      height: calc(100vh - 2em - 200px);
      min-height: 400px;
      max-height: 600px;
      margin-top: 1em;
    }

    @media (max-width: 800px) {
      flex-direction: column-reverse;

      .results {
        width: calc(100% - 1em);

        th ion-avatar {
          width: 35px;
          height: 35px;
        }

        tr th {
          font-size: 0.75em;
        }
      }

      .chart-container {
        width: calc(100% - 1em);
        min-width: 0;
        margin: 0 1em;
      }
    }
  }

  .header {
    width: 100%;
    text-align: left;
  }
}
</style>
