
import { defineComponent, ref } from 'vue'

import GeneralSegment from '@/views/SettingsView/GeneralSegment.vue'
import CloudSegment from '@/views/SettingsView/CloudSegment.vue'

enum Section {
  General = 'config',
  Cloud = 'cloud',
}

export default defineComponent({
  components: {
    GeneralSegment,
    CloudSegment,
  },

  setup() {
    const state = {
      section: ref<Section>(Section.General),
    }

    return {
      ...state,
    }
  },
})
