import { Storage } from '@capacitor/core'
import { Config, CONFIG_DEFAULTS } from '../config.interface'
import { ConfigRepository } from '../config.repository'

/**
 * Uses `capacitor`'s Storage.
 */
export class ConfigLocalRepository implements ConfigRepository {
  /** @inheritdoc */
  async get(): Promise<Config> {
    return this.getFromStore<Config>('config', CONFIG_DEFAULTS)
  }

  /** @inheritdoc */
  async update(config: Config): Promise<Config> {
    await Storage.set({ key: 'config', value: JSON.stringify(config) })

    return config
  }

  /**
   * Tries to get a key from the storage, defaults to the given value.
   *
   * @TODO improve logging :eyes:
   *
   * @param key
   * @param defaultValue
   */
  protected async getFromStore<T>(key: string, defaultValue: T): Promise<T> {
    try {
      const resultsJson = await Storage.get({ key })

      return (JSON.parse(resultsJson.value) as T) || defaultValue
    } catch (error) {
      console.error(error)
    }

    return defaultValue
  }
}
