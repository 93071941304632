import { Inject, Service } from 'typedi'
import { Plugins } from '@capacitor/core'
import { AuthRepository } from './interfaces/auth.repository'
import { AUTH_REPOSITORY_TOKEN } from '../../constants'

const { Device } = Plugins

@Service()
export class AuthService {
  constructor(@Inject(AUTH_REPOSITORY_TOKEN) protected readonly repository: AuthRepository) {}

  initAuth() {
    // console.log('init auth')
  }

  async getDeviceId(): Promise<string> {
    const info = await Device.getInfo()

    return info.uuid
  }

  async getToken(): Promise<string> {
    return this.repository.getToken(await this.getDeviceId())
  }
}
