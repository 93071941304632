
import { defineComponent } from 'vue'
import { hasSurveys } from '../modules/survey/survey.store'

import FaceImage from '@/components/FaceImage.vue'

export default defineComponent({
  components: {
    FaceImage,
  },

  setup() {
    const getters = {
      hasSurveys,
    }

    return {
      ...getters,
    }
  },
})
