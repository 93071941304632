<template>
  <div class="unlock-modal-content" :class="{ 'has-error': hasError }">
    <div class="header">
      <div class="code-dots">
        <div
          v-for="position in [0, 1, 2, 3]"
          :key="position"
          class="code-dot"
          :class="{ filled: code.length > position }"
        />
      </div>
    </div>

    <div class="dial">
      <div class="dial-row">
        <ion-button
          v-for="number in [1, 2, 3]"
          :key="number"
          class="dial-button"
          shape="round"
          fill="outline"
          size="large"
          color="dark"
          v-text="number"
          @click="addCode(number)"
        />
      </div>
      <div class="dial-row">
        <ion-button
          v-for="number in [4, 5, 6]"
          :key="number"
          class="dial-button"
          shape="round"
          fill="outline"
          size="large"
          color="dark"
          v-text="number"
          @click="addCode(number)"
        />
      </div>
      <div class="dial-row">
        <ion-button
          v-for="number in [7, 8, 9]"
          :key="number"
          class="dial-button"
          shape="round"
          fill="outline"
          size="large"
          color="dark"
          v-text="number"
          @click="addCode(number)"
        />
      </div>
      <div class="dial-row">
        <ion-button
          v-for="number in [0]"
          :key="number"
          class="dial-button"
          shape="round"
          fill="outline"
          size="large"
          color="dark"
          v-text="number"
          @click="addCode(number)"
        />
      </div>
    </div>
    <div class="buttons-container">
      <ion-button class="button-cancel" fill="clear" @click="$emit('cancel')">Cancel</ion-button>
      <ion-button
        v-if="!isLock"
        class="button-ok"
        fill="solid"
        :disabled="code.length < 4"
        @click="$emit('submit', code)"
        >Submit</ion-button
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { config } from '@/modules/config/config.setup'
import { wait } from '../../util/time'

export default defineComponent({
  props: {
    isLock: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['unlocked', 'cancel'],

  setup(props: any, { emit }: any) {
    const state = {
      code: ref<string>(''),
      hasError: ref<boolean>(false),
    }

    const methods = {
      async addCode(number: number) {
        if (state.code.value.length < 4) {
          state.code.value += number.toString()

          if (props.isLock && state.code.value.length === 4) {
            if (state.code.value === config.value.securityCode) {
              emit('unlocked')
            } else {
              state.hasError.value = true

              await wait(1000)

              state.code.value = ''
              state.hasError.value = false
            }
          }
        }
      },
    }

    return {
      ...state,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

.unlock-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    .code-dots {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1em;

      .code-dot {
        width: 12px;
        height: 12px;
        margin: 0 1em;
        border: 1px solid var(--ion-text-color);
        border-radius: 50%;
        transition: background-color 0.4s ease-in-out, border-color 0.4s ease-in-out;
        animation-duration: 1s;
        animation-fill-mode: both;

        &.filled {
          background: white;
        }
      }
    }
  }

  .dial {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dial-row {
      display: flex;

      ion-button {
        --ion-color-dark: var(--ion-text-color);
      }
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 1em 0;
  }

  &.has-error {
    .code-dots .code-dot {
      border-color: var(--ion-color-danger);
      animation: shake 1s;
      &.filled {
        background-color: var(--ion-color-danger);
      }
    }
  }

  @media (max-height: 400px) {
    flex-direction: row;

    .header {
      flex: 1;
      .code-dots {
        flex-direction: column;

        .code-dot {
          margin: 1em 0;
        }
      }
    }

    .button-cancel-container {
      flex: 1;
    }
  }
}
</style>
