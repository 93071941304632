<template>
  <div class="overview">
    <h2 class="ion-padding" v-text="`#1: ${survey.questions[0].text}`" />

    <div class="results-container">
      <ion-list class="results">
        <ion-item v-for="(answer, index) in answers" :key="index">
          <ion-avatar slot="start">
            <face-image :answer-type="answer.type" :is-active="scoredAnswerType === answer.type" />
          </ion-avatar>
          <ion-label v-text="answer.text" />
          <ion-note slot="end" v-text="survey.questions[0].answers[answer.type].quantity" />
        </ion-item>
        <ion-item>
          <ion-label v-text="'Total answers'" />
          <ion-note slot="end" v-text="totalAnswers" color="dark" />
        </ion-item>
      </ion-list>

      <div class="chart-container">
        <totals-chart :totals="totals" />
      </div>
    </div>

    <div class="fill" />

    <div class="description ion-padding">
      <span v-text="survey.description" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import TotalsChart from '@/components/Charts/TotalsChart.vue'
import FaceImage from '@/components/FaceImage.vue'
import { Survey } from '@codeserk/happy-food-server'
import {
  getTotalAnswers,
  getActiveAnswers,
  getScoredAnswerType,
  getScore,
  getAggregationFromAnswers,
} from '@/modules/survey/survey.model'

export default defineComponent({
  props: {
    survey: {
      type: Object,
      default: () => (({} as any) as Survey),
    },
  },

  components: {
    TotalsChart,
    FaceImage,
  },

  setup(props: any) {
    const getters = {
      answers: computed(() => getActiveAnswers(props.survey)),
      score: computed(() => getScore(props.survey)),
      scoredAnswerType: computed(() => getScoredAnswerType(props.survey)),
      totals: computed(() => getAggregationFromAnswers(props.survey.questions[0].answers)),
      totalAnswers: computed(() => getTotalAnswers(props.survey)),
    }

    const methods = {
      getActiveAnswers,
      getScore,
      getScoredAnswerType,
      getAggregationFromAnswers,
    }

    return {
      ...getters,
      ...methods,
    }
  },
})
</script>

<style lang="scss" scoped>
.overview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;

  .results-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1em;
    margin-right: 1em;

    .results {
      flex: 1;
      margin-right: 1em;
    }

    .chart-container {
      width: 350px;
      height: 250px;
      margin-top: 1em;
    }

    @media (max-width: 400px) {
      flex-direction: column-reverse;
      margin-right: 0;

      .results {
        width: 100%;
        margin: 0;
      }

      .chart-container {
        width: calc(100% - 1em);
        min-width: 0;
        margin: 0 1em;
      }
    }
  }

  .fill {
    flex: 1;
  }

  .header {
    width: 100%;
    text-align: left;
  }

  .description {
    width: 100%;
    text-align: left;
    white-space: pre;
  }
}
</style>
