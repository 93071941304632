
import { computed, defineComponent, ref } from 'vue'
import { answerTypes } from '@/modules/survey/survey.store'
import { StatsAggregationType, Survey } from '@codeserk/happy-food-server'

import FaceImage from '@/components/FaceImage.vue'
import { config } from '../../../config'

export default defineComponent({
  components: {
    FaceImage,
  },

  props: {
    survey: {
      type: Object,
      default: () => (({} as any) as Survey),
    },
  },

  setup(props: any) {
    const constants = {
      answerTypes,
      aggregationsTypes: Object.entries(StatsAggregationType),
    }

    const state = {
      aggregationType: ref<StatsAggregationType>(StatsAggregationType.Day),
    }

    const getters = {
      token: computed(() => props.survey?.readToken),
      encodedToken: computed((): string => (getters.token.value ? encodeURIComponent(getters.token.value) : '')),
      link: computed((): string | undefined => {
        if (!getters.encodedToken.value) {
          return
        }

        return `${config.frontendBaseUrl}/share/${getters.encodedToken.value}`
      }),

      linkDocs: computed(() => `${config.publicBaseUrl}/docs`),
      linkSurvey: computed((): string => `${config.publicBaseUrl}/public/v1/survey?k=${getters.encodedToken.value}`),
      linkStats: computed(
        (): string => `${config.publicBaseUrl}/public/v1/survey/stats?k=${getters.encodedToken.value}`,
      ),
      linkStatsAggregated: computed(
        (): string =>
          `${config.publicBaseUrl}/public/v1/survey/question/0/stats/aggregated?aggregation=${state.aggregationType.value}&k=${getters.encodedToken.value}`,
      ),
    }

    return {
      ...constants,
      ...state,
      ...getters,
      onClicked() {
        //
      },
    }
  },
})
